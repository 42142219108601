import { makeStyles } from '@material-ui/core/styles'
import { theme } from '../../theme'

const styles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: "column",
        marginBottom: theme.spacing(8)

    },
    logoSection: {
        display: "flex",
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexWrap: "wrap"
        },
    },
    buttonsContainer: {
        display: "flex",
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexWrap: "wrap"
        },
    },
    goToButton: {
        ...theme.typography.h4,
        color: theme.palette.common.white,
        height: "max-content",
        textTransform: "uppercase",
        marginTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(1),
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    title: {
        ...theme.typography.h2,
        color: theme.palette.grey[500]
    },
    instructorIndividual: {
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "25%",
        padding: "0px 8px",
        '&:hover': {
            cursor: "pointer"
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: "100%",
            marginTop: theme.spacing(1)
        }
    },
    instructorMenuSection: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            flexWrap: "wrap",
            flexDirection: "column"
        },
        paddingTop: theme.spacing(2),
        paddingBottom: "0px",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    instructorTitle: {
        ...theme.typography.h3,
        color: theme.palette.grey[500]
    },
    instructorContent: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"

    }
})

export default styles;