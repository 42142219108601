import { Paper, Typography } from '@material-ui/core';
import React from 'react'
import styles from './styles'

const IndividualPathway = ({logo, title, description}) => {

    const classes = new styles();

    return (
        <Paper variant="outlined" className={classes.container} >
            <img 
                src={logo}
                alt={title}
            />
            <Typography  className={classes.title}>
                    {title}
            </Typography>
            <Typography  className={classes.description} >
                {description}
            </Typography>
        </Paper>
    )
}


export default IndividualPathway;
