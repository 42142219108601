import React from 'react';
import axios from 'axios';
import { Loading } from '../ui';
import { Box, Button, Typography } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import styles from './styles'

const Download = ({ match }) => {
    const classes = new styles();
    const [isLoading, setIsLoading] = React.useState(true);
    const [info, setInfo] = React.useState({});
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        const getInfo = async () => {
            try {
                const response = await axios.get(`/Download/GetFileInfo?appName=${match.params.appName}`);
                setIsLoading(false);
                setInfo(response.data);
            } catch (error) {
                setIsLoading(false);
                setError(error);
            };
        }

        getInfo();
    }, [match.params.appName]);

    const onClick = () => {
        window.open(info.downloadUrl, '_blank').focus();
    };

    if (isLoading) {
        return <Loading />
    }

    if (error) {
        return (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{}} mt={5}>
                <Typography component="h1" className={classes.brandTitle} >
                    Something went wrong!
                </Typography>
                <Typography component="p" className={classes.brandDesc}>
                    {error.message}
                </Typography>
            </Box>
        );
    }

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{}} mt={5}>
            <Typography component="h1" className={classes.brandTitle}>
                {info.title}
            </Typography>
            <Typography component="p" className={classes.brandDesc}>
                {info.description}
            </Typography>
            <Box display="flex" flexDirection="row" mt={2}>
                <Typography variant="subtitle1">
                    Release Date:&nbsp;
                </Typography>
                <Typography variant="subtitle2">
                    {info.releaseDate}
                </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
                <Typography variant="subtitle1">
                    Version:&nbsp;
                </Typography>
                <Typography variant="subtitle2">
                    {info.version}
                </Typography>
            </Box>
            <Box display="flex" flexDirection="row" mb={2}>
                <Typography variant="subtitle1">
                    Size:&nbsp;
                </Typography>
                <Typography variant="subtitle2">
                    {info.size}
                </Typography>
            </Box>
            <Button variant="contained" color="primary" startIcon={<CloudDownloadIcon />} onClick={onClick}>Download File</Button>
        </Box >
    );
};

export default Download;