import {makeStyles} from '@material-ui/core/styles'
import {theme} from '../../../../theme'


const styles = makeStyles({
        container:{
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            maxWidth:"15%",
            minHeight:"15%",
            padding:theme.spacing(2),
            [theme.breakpoints.down("md")]:{
                minWidth:"18%",
                maxWidth:"18%"
            },
            [theme.breakpoints.down("xs")]:{
                minWidth:"100%",
                maxWidth:"100%"
            },
        },
        title:{
            ...theme.typography.h4,
            marginTop:theme.spacing(1),
            marginRight:"0px",
            marginBottom:theme.spacing(3),
            marginLeft:"0px",
            fontWeight:"500"
        },
        description:{
            ...theme.typography.body1,
            color:theme.palette.grey[400]
        }
})


export default styles;