import React from 'react'
import { Box, Button, Card, CardContent, Typography, useMediaQuery } from "@material-ui/core"
import styles from './style'
import { default as AdobeInstallatinGuide } from '../adobeInstallationGuide/Main';

const SubscribedProduct = ({ name, theme, logo, goToTitle, url, role, instructorMenus }) => {
    const classes = new styles();
    const isMatched = useMediaQuery('(max-width:750px)');
    //const isMobileScreen = useMediaQuery('(max-width:600px)');
    const [openAdobeInstallationGuide, setOpenAdobeInstallationGuide] = React.useState(false);

    const openItem = url => {
        if (logo.indexOf("jasperactive-ac.") > -1) {
            setOpenAdobeInstallationGuide(true);
        } else {
            window.open(url, "_blank");
        }
    }

    const goToRespectiveUrl = url => {
        window.location.href = url
    }

    return (
        <React.Fragment>
            <Card className={classes.container} style={{ borderTop: "4px solid" + theme }} >
                <CardContent className={classes.logoSection} style={{ flexDirection: isMatched ? "column" : "" }}>
                    <img
                        src={logo}
                        alt={name}
                        style={{ width: "200px" }}
                    />
                    <Box className={classes.buttonsContainer}>
                        {role === 'Student' && url === 'https://www.office.com' &&
                            <Button className={classes.goToButton} style={{ backgroundColor: theme }} variant="outlined" onClick={() => {
                                openItem("https://mos.jasperactive.com");
                            }}>
                                Go To Jasperactive Microsoft Office
                            </Button>
                        }
                        <Button className={classes.goToButton} style={{ backgroundColor: theme }} variant="outlined" onClick={() => {
                            openItem(url);
                        }}>
                            {goToTitle}
                        </Button>
                    </Box>
                </CardContent>
                {role === "Instructor" &&
                    <CardContent className={classes.instructorMenuSection} >
                        {instructorMenus.map((value, i) => {
                            return (
                                <Card key={i} className={classes.instructorIndividual} style={{ visibility: name.toLowerCase() === "jasperactive app" && value.title === "Instructor Resources" ? "hidden" : "visible" }} onClick={() => { goToRespectiveUrl(value.url) }} >
                                    <CardContent className={classes.instructorContent} style={{ paddingBottom: "16px" }} >
                                        <img
                                            src={value.icon}
                                            alt={value.title}
                                            style={{ marginRight: "10px", maxWidth: "336px" }}
                                        />
                                        <Typography className={classes.instructorTitle} >
                                            {value.title}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            );
                        })}
                    </CardContent>
                }
            </Card>
            <AdobeInstallatinGuide open={openAdobeInstallationGuide} setOpen={setOpenAdobeInstallationGuide} />
        </React.Fragment >
    )
}


export default SubscribedProduct;