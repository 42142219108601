import { Typography } from '@material-ui/core';
import React from 'react'
import styles from './styles'
import Product from './Product/Product'
import {products} from '../../../lib/productsData'


const Products = () => {

    const classes = new styles();

    return (
        <div className={classes.container}  id="products" >
            <Typography className={classes.title} >
                Our Products
            </Typography>
            <div className={classes.productContainer} >
                {products().map((product, i) => {
                    return <Product  key={i} logo={product.logo}  description={product.description} theme={product.theme} type={product.prodType} />
                })}
            </div>
        </div>
    )
}


export default Products;
