import React from 'react'
import {useHistory} from 'react-router-dom'
import { Typography } from "@material-ui/core"
import styles from './styles';

const InfoPanel = () => {

    const classes = new styles();
    const history = useHistory();

    const goToCCI = () => {
        window.open("https://ccilearning.com/", "_blank")
    }

    const goTosupport = () => {
        window.open("https://support.jasperactive.com/support/home", "_blank");
    }

    const goToPricing = () => {
        history.push("/pricing")
    }

    return (
        <div component="div" className={classes.infoPanel}  >
            <div className={classes.infoContainerLeft} >
                <Typography  component="div" className={classes.infoText}  onClick={goToCCI} >
                    CCI Learning
                    </Typography>
                <div className={classes.separator} ></div>
                <Typography component="div" className={classes.infoText} onClick={goTosupport} >
                    Help
                    </Typography>
            </div>
            <div className={classes.infoContainerRight} >
                <Typography className={classes.infoPanelDescription} component="div" >
                    We provide special pricing for academic customers. 
                    </Typography>
                <Typography component="div" className={classes.infoText}  onClick={goToPricing} >
                    Find out more
                    </Typography>
            </div>
        </div>
    )
}

export default InfoPanel;
