import SETTINGS from './settings';
import { UserManager, WebStorageStateStore } from 'oidc-client';

const clientId = "5141b488-4783-4577-af2b-321c29ea3b42";

export class Authentication {
    constructor() {
        this.userManager = new UserManager({
            authority: SETTINGS.identityUrl,
            client_id: clientId,
            redirect_uri: `${SETTINGS.appUrl}/auth-callback`,
            post_logout_redirect_uri: `${SETTINGS.appUrl}/logout`,
            response_type: "code",
            response_mode: 'query',
            scope: "openid profile email JasperactiveWebApi",
            filterProtocolClaims: true,
            loadUserInfo: true,
            automaticSilentRenew: true,
            silent_redirect_uri: `${SETTINGS.appUrl}/silent-renew`,
            revokeAccessTokenOnSignout: true,
            userStore: new WebStorageStateStore({ store: window.sessionStorage })
        });

        this.userManager.events.addUserLoaded((user) => {

        });

        this.userManager.events.addSilentRenewError((e) => {
            // console.log('silent renew error', e.message);
        });

        this.userManager.events.addAccessTokenExpiring((e) => {
            this.refreshAccessToken();
        });

        this.userManager.events.addAccessTokenExpired(() => {
            // console.log('Access token has expired: ', new Date());
        });
    }

    refreshAccessToken = () => {
        const isRefreshingAccessToken = sessionStorage.getItem("isRefreshingAccessToken");
        if (!isRefreshingAccessToken) {
            sessionStorage.setItem("isRefreshingAccessToken", "true");
            // console.log("Refreshing access token...");
            this.signinSilent(() => { sessionStorage.removeItem("isRefreshingAccessToken"); });
        }
    }

    clearStorage = (onSuccess) => {
        this.userManager.clearStaleState().then(() => {
            localStorage.clear();
            sessionStorage.clear();

            if (onSuccess) {
                onSuccess();
            }
        });
    };

    clearStorageAsync = async (onSuccess) => {
        await this.userManager.clearStaleState();
        localStorage.clear();
        sessionStorage.clear();

        if (onSuccess) {
            onSuccess();
        }
    };

    signoutRedirectCallback = () => {
        this.userManager.signoutRedirectCallback().then(() => {
            localStorage.clear();
        });
        this.userManager.clearStaleState();
    };

    signinSilent = (onFinish) => {
        try {
            this.userManager.signinSilent().then(user => {
                // console.log('sign in silent completed, token expires at', getExpirationTime());
                // console.log('Access token:', getAccessToken());
                onFinish?.(user);
            }).catch(error => {
                console.error("sign in silent failed:", error);
                onFinish?.();
            });
        } catch (error) {
            console.error(error);
            onFinish?.();
        }
    }

    createSession = user => {
        if (user && user.access_token) {
            sessionStorage.setItem(`oidc.user:${SETTINGS.identityUrl}:JasperactiveAddin`, JSON.stringify(user));
        }
    };
}

export const getSessionInfo = () => {
    return JSON.parse(
        sessionStorage.getItem(`oidc.user:${SETTINGS.identityUrl}:${clientId}`),
    );
}

export const getAccessToken = () => {
    const sessionInfo = getSessionInfo();

    if (sessionInfo)
        return sessionInfo.access_token;

    return null;
}

export const getExpirationTime = () => {
    const sessionInfo = getSessionInfo();
    let date = new Date('1970-01-01T00:00:00Z');
    date.setTime(parseInt(sessionInfo.expires_at) * 1000);
    return new Date(date);
};
