import { makeStyles } from "@material-ui/core/styles"
import { theme } from '../../theme'

const styles = makeStyles({
    brandTitle: {
        fontSize: "62px",
        fontWeight: "500",
        lineHeight: "72px",
        marginBottom: theme.spacing(3),
        color: theme.palette.grey[400],
        [theme.breakpoints.down("md")]: {
            fontSize: "46px",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "46px",
            lineHeight: "40px"
        }
    },
    brandDesc: {
        ...theme.typography.h3,
        color: theme.palette.grey[500],
        [theme.breakpoints.down("md")]: {
            fontSize: "18px"
        },
        [theme.breakpoints.down("xs")]: {
            ...theme.typography.body2,
            marginTop: theme.spacing(4)
        },
    },
})

export default styles;