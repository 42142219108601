import React, { useState } from "react";
import { Typography, TextField, Button } from "@material-ui/core";
import styles from "./style";
import axios from 'axios'
import { useSnackbar } from 'notistack';

const ContactUs = () => {
  const classes = new styles();
  const [error, setError] = useState({
    show: 0,
    message: "",
  });

  const { enqueueSnackbar } = useSnackbar();


  const [userData, setUserData] = useState({
    name: "",
    companyName: "",
    jobTitle:"",
    email: "",
    phoneNumber: "",
    city: "",
    country: "",
    message:""
  });

  const submitData = () => {
    if (!userData.name) {
      setError({
        show: 1,
        message: "Name is required",
      });
      return;
    }

    if (!userData.companyName) {
      setError({
        show: 2,
        message: "Company Name is required",
      });
      return;
    }

    if (!userData.jobTitle) {
      setError({
        show: 3,
        message: "Job Title is required",
      });
      return;
    }

    if (!userData.email) {
      setError({
        show: 4,
        message: "Email is required",
      });
      return;
    }

    if (!validateEmail(userData.email)) {
      setError({
        show: 4,
        message: "Enter valid email",
      });
      return;
    }

    if (!userData.phoneNumber) {
      setError({
        show: 5,
        message: "Phone number is required",
      });
      return;
    }

    if (!validatePhone(userData.phoneNumber)) {
      setError({
        show: 5,
        message: "Enter valid phone number",
      });
      return;
    }

    if (!userData.city) {
      setError({
        show: 6,
        message: "City is required",
      });
      return;
    }

    if (!userData.country) {
      setError({
        show: 7,
        message: "Country is required",
      });
      return;
    }

    axios.post("/Home/Contact",  userData )
    .then(response => {
      const data = response.data;

      if(data.success){
        enqueueSnackbar("Contact Form Successfully Submitted", {
          variant:"success"
        })
      }

      if(!data.success){
        enqueueSnackbar(data.message, {
          variant:"error"
        })
      }

      setUserData({
        name:"",
        companyName:"",
        jobTitle:"",
        email:"",
        phoneNumber:"",
        city:"",
        country:"",
        message:""
      })

    }).catch(error => {
      if(error){
        enqueueSnackbar(error.message, {
          variant:"error"
        })
      }
    })



  };

  const resetError = () => {
    setError({
      show: 0,
      message: "",
    });
  };

  const validateEmail = (email) => {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phonenumber) => {
    let re = /^\d{10}$/;
    return re.test(String(phonenumber));
  };

  return (
    <div className={classes.contactUsSection} id="contactus">
      <Typography className={classes.contactUsSectionTitle}>
      Are you interested in learn more about our offerings?
      </Typography>
      <Typography className={classes.contactusSubtitle}>
        Contact us by submitting the form below :
      </Typography>
      <div className={classes.contactForm}>
        <TextField
          id="name"
          error={error.show === 1}
          helperText={error.show === 1 && error.message}
          InputProps={{
            className: classes.inputField,
          }}
          fullWidth
          size="medium"
          required={true}
          type="text"
          value={userData.name}
          placeholder="Name *"
          onChange={(e) => {
            resetError();
            setUserData({ ...userData, name: e.target.value });
          }}
        />
        <TextField
          id="companyName"
          error={error.show === 2}
          helperText={error.show === 2 && error.message}
          InputProps={{
            className: classes.inputField,
          }}
          fullWidth
          value={userData.companyName}
          type="text"
          size="medium"
          placeholder="Company Name *"
          onChange={(e) => {
            resetError();
            setUserData({ ...userData, companyName: e.target.value });
          }}
        />
        <TextField
          id="jobTitle"
          error={error.show === 3}
          value={userData.jobTitle}
          helperText={error.show === 3 && error.message}
          InputProps={{
            className: classes.inputField,
          }}
          fullWidth
          type="text"
          size="medium"
          placeholder="Job Title *"
          onChange={(e) => {
            resetError();
            setUserData({ ...userData, jobTitle: e.target.value });
          }}
        />
        <TextField
          id="email"
          error={error.show === 4}
          helperText={error.show === 4 && error.message}
          value={userData.email}
          InputProps={{
            className: classes.inputField,
          }}
          fullWidth
          size="medium"
          type="email"
          placeholder="Email *"
          onChange={(e) => {
            resetError();
            setUserData({ ...userData, email: e.target.value });
          }}
        />
        <TextField
          id="phonenumber"
          error={error.show === 5}
          helperText={error.show === 5 && error.message}
          InputProps={{
            className: classes.inputField,
          }}
          fullWidth
          size="medium"
          type="tel"
          value={userData.phoneNumber}
          placeholder="Phone Number *"
          onChange={(e) => {
            resetError();
            setUserData({
              ...userData,
              phoneNumber: e.target.value,
            });
          }}
        />
        <TextField
          id="city"
          error={error.show === 6}
          helperText={error.show === 6 && error.message}
          InputProps={{
            className: classes.inputField,
          }}
          fullWidth
          size="medium"
          value={userData.city}
          type="text"
          placeholder="City *"
          onChange={(e) => {
            resetError();
            setUserData({ ...userData, city: e.target.value });
          }}
        />
        <TextField
          id="country"
          error={error.show === 7}
          helperText={error.show === 7 && error.message}
          InputProps={{
            className: classes.inputField,
          }}
          fullWidth
          size="medium"
          value={userData.country}
          type="text"
          placeholder="Country *"
          onChange={(e) => {
            resetError();
            setUserData({
              ...userData,
              country:e.target.value
            })
          }}
        />
        <TextField
          InputProps={{
            className: classes.messageField,
          }}
          fullWidth
          size="medium"
          value={userData.message}
          multiline={true}
          type="text"
          rowsMax={5}
          rows={5}
          placeholder="Your Message"
          onChange={(e) => {
            resetError();
            setUserData({...userData, message:e.target.value})
          }}
        />
        <Button
          className={classes.submitBtn}
          variant="outlined"
          onClick={submitData}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ContactUs;
