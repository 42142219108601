import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Button,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import styles from "./styles";

const PrivacyPolicy = () => {
  const classes = styles();
  const [show, setShow] = useState(true);
  const { user } = useAuthentication();
  const history = useHistory();
  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    if(document.cookie.split(';').some((item) => item.trim().startsWith('email='))){
      setShow(false);
    }else{
      setShow(true);
    }

  }, [user]);

  const setCookie = () => {
    let userEmail = "email";
    if (user) {
      userEmail = user.email;
    }
    let date = new Date();
    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
    var expires = "expires=" + date.toUTCString();
    document.cookie = "email=" + userEmail + ";" + expires + ";path=/";
    setShow(false);
  };

  return (
    <>
      {show ? (
        <div className={classes.container}>
          <Box
            display="flex"
            flexDirection={isMatched ? "column" : "row"}
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              className={classes.title}
              style={{ marginBottom: isMatched ? 8 : 0 }}
            >
              We use cookies to ensure that we give you the best experience on
              our website. If you continue to use this site we will assume that
              you are happy with it.
            </Typography>
            <Box display="flex">
              <Button className={classes.btn} onClick={setCookie}>
                Ok
              </Button>
              <Button
                className={classes.btn}
                onClick={() => {
                  history.push("/statement-of-privacy");
                }}
              >
                Privacy Policy
              </Button>
            </Box>
          </Box>
          <Box p={1}>
            <CloseIcon
              style={{ color: theme.palette.common.white }}
              onClick={() => {
                setShow(false);
              }}
            />
          </Box>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PrivacyPolicy;
