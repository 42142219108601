import React from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import styles from "./styles";

const Loading = ({ message }) => {
    const classes = new styles();
    return (
        <Box className={classes.container}>
            <CircularProgress size={50} color="primary" />
            <Box margin={5}>
                <Typography>
                    {message ? message : "Loading, please wait..."}
                </Typography>
            </Box>
        </Box>
    );
};

export default Loading;