import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../theme';

const styles = makeStyles({
    appShell:{
        height:"100%",
        display:"flex",
        flexDirection:"column",
        overflow:"auto",
        background:theme.palette.common.white,
    },

    appShellJDL: {
        height:"100vh",
        display:"flex",
        flexDirection:"column",
        overflow:"auto",
        background:theme.palette.common.white,
    },

    main:{
        height:"100%",
        minHeight:"90vh",
        display:"flex",
        flexDirection:"column",
    },

    mainJDL: {
        width: '100%',
        display:"flex",
        flexDirection:"column",
        padding: 0,
        flex: 1,
        maxWidth: 'unset'
    }

})


export default styles;