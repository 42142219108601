import { Container } from '@material-ui/core'
import React from 'react'
import styles from './styles';
import { AuthenticationProvider } from "../../../providers/AuthenticationProvider";
import { useLocation } from 'react-router-dom';

const Appshell = ({ children, header, footer, privacy }) => {
    const classes = styles();
    let location = useLocation();

    return (
        <AuthenticationProvider>
            <div className={location.pathname === '/jdl' ? classes.appShellJDL : classes.appShell} >
                {header}
                <Container className={location.pathname === '/jdl' ? classes.mainJDL : classes.main} >
                    {children}
                </Container>
                {location.pathname === '/jdl' ? null : footer}
                {location.pathname === '/jdl' ? null : privacy}
            </div>
        </AuthenticationProvider>
    )
}

export default Appshell;