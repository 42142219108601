import React from 'react';
import { useHistory } from 'react-router-dom'
import { useAuthentication } from '../../../providers/AuthenticationProvider';
import { Loading } from "../../ui";

const Logout = () => {
    const { authentication, setUser } = useAuthentication();
    const history = useHistory();

    React.useEffect(() => {
        const logout = async () => {
            await authentication.userManager.signoutRedirectCallback();
            await authentication.userManager.clearStaleState();
            localStorage.clear();
            sessionStorage.clear();
            setUser(null);
            history.push("/");
        };

        logout();
    }, [authentication.userManager, history, setUser]);

    return (
        <Loading message="Logging out, please wait..." />
    );
};

export default Logout;
