import { Typography } from '@material-ui/core'
import React from 'react'
import styles from './styles'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'
import CertiportLogo from '../../../assets/logos/partners/certiport-logo.png'
import CertiportHover from '../../../assets/logos/partners/certiport-logo-hover.png'
import MicrosoftLogo from '../../../assets/logos/partners/Microsoft-Logo.svg';
import MicrosoftHoverLogo from '../../../assets/logos/partners/Microsoft-Logo-Hover.svg'
import GmetrixLogo from '../../../assets/logos/partners/gmetrix-logo-new.png'; 
import GmetrixHoverLogo from '../../../assets/logos/partners/gmetrix-logo-hover.svg'; 
import IntuitLogo from '../../../assets/logos/partners/Intuit-Logo.svg';
import IntuitHoverLogo from '../../../assets/logos/partners/Intuit-Logo-Hover.svg';
import IC3Logo from '../../../assets/logos/partners/IC3-Logo.svg';
import IC3HoverLogo from '../../../assets/logos/partners/IC3-Logo-Hover.svg';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import './carouselStyle.css'



const Partners = () => {

    const classes = new styles();


    const responsive = {
        desktop: {
            breakpoint: { max: 5000, min: 1280 },
            items: 5,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1280, min: 960 },
            items: 4
        },
        miniTable:{
            breakpoint: { max: 960, min : 600},
            items:3
        },
        mobile: {
            breakpoint: { max: 600, min: 300 },
            items: 1
          },
    }

    const renderPartners = () => {
        let partners = [
            {
                name:"Certiport",
                src:CertiportLogo ,
                url:"https://certiport.pearsonvue.com/",
                hoverSrc:CertiportHover
            },
            {
                name:"Microsoft",
                src:MicrosoftLogo,
                url:"https://www.microsoft.com/",
                hoverSrc:MicrosoftHoverLogo
            },
            {
                name:"Gmetrxi",
                src:GmetrixLogo,
                url:"https://www.gmetrix.com/",
                hoverSrc:GmetrixHoverLogo
            },
            {
                name:"Intuit",
                src: IntuitLogo,
                url:"https://www.intuit.com/",
                hoverSrc:IntuitHoverLogo
            },
            {
                name:"Ic3",
                src:IC3Logo,
                url:"https://certiport.pearsonvue.com/Certifications/IC3/Digital-Literacy-Certification/Overview.aspx",
                hoverSrc:IC3HoverLogo
            }
            
        ];
        return partners;
    }

    const CustomLeftArrow = ({ onClick, ...rest }) => {
        
        return <ChevronLeftIcon className="react-multiple-carousel__arrow react-multiple-carousel__arrow--Left" onClick={() => onClick()} />;
      };

    const CustomRightArrow = ({ onClick, ...rest }) => {
        return <ChevronRightIcon className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right" onClick={() => onClick()} />;
      };

    const applyHoverImage = (e, hoverSrc) => {
        e.target.src = hoverSrc
    }

    const applyDefaultImage = (e, defaultSrc) => {
        e.target.src = defaultSrc
    }

    

    return (
        <div className={classes.container}>
            <Typography  className={classes.title} >
                Our Partners
            </Typography>
            <Carousel
                customRightArrow={<CustomRightArrow  />}
                className={classes.carousel}
                customLeftArrow={<CustomLeftArrow  />}
                responsive={responsive}
                infinite={true}
                autoPlay={false}

            >
                {
                    renderPartners().map((partner, i) => {
                        return <div key={i}   >
                            <img 
                                className={classes.partners}
                                src={partner.src}
                                alt={partner.name}
                                onClick={() => { window.open(partner.url, "_blank") }}
                                onMouseOver={(e) => {applyHoverImage(e, partner.hoverSrc)}}
                                onMouseOut={(e) => {applyDefaultImage(e, partner.src)}}
                                style={{width:i === 0 || i === 1 ? "130px" : i === 4 ? "150px" : "100px"}}
                            />
                        </div>
                    })
                }
            </Carousel>

        </div>
    )
}


export default Partners;
