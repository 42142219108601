import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";

const styles = makeStyles({
    container:{
        display:"flex",
        flexDirection:"column",
        marginTop:theme.spacing(2)
    },
    question:{
        ...theme.typography.h4,
        color:theme.palette.common.black,
        fontWeight:500
    },
    answer:{
        ...theme.typography.body1,
        color:theme.palette.common.black
    }
    
})


export default styles;