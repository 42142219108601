import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import { theme } from "../../theme";
import WindowsButtonIcon from '../../assets/other/windows-button-logo.svg';
import AppleButtonIcon from '../../assets/other/apple-button-logo.svg';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import JACIcon from '../../assets/logos/products/jasperactive-ac-icon.svg';

const useStyles = makeStyles({
    dialog: {
        borderTop: `4px solid ${theme.palette.product.adobeCreate}`,
    },
    textBox: {
        display: "flex",
        [theme.breakpoints.up('xs')]: {
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
        },
    },
    text: {
        [theme.breakpoints.up('xs')]: {
            marginLeft: theme.spacing(2),
        },
    },
    buttonBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        height: "100px",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    button: {
        borderColor: theme.palette.product.adobeCreate
    },
});

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(({ children, classes, onClose, ...other }) => {
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const OperatingSystemDialog = ({ open, onClose, onClick }) => {
    const classes = useStyles();

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} >
            <DialogTitle className={classes.dialog} onClose={handleClose}>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Box className={classes.textBox}>
                        <Box>
                            <img src={JACIcon} alt="Jasperactive Adobe Create" />
                        </Box>
                        <Box>
                            <Typography variant="body1" className={classes.text}>
                                If you have already installed the Jasperactive extension then open Adobe Photoshop and start the course.
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.buttonBox}>
                        <Button variant="outlined" className={classes.button} onClick={() => { onClick?.("windows") }} endIcon={<img src={WindowsButtonIcon} alt="Windows" />}>
                            INSTALLATION GUIDE FOR WINDOWS
                        </Button>
                        <Button variant="outlined" className={classes.button} onClick={() => { onClick?.("mac") }} endIcon={<img src={AppleButtonIcon} alt="Mac" />}>
                            INSTALLATION GUIDE FOR MAC
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default OperatingSystemDialog;