import Jmosonline from "../assets/other/JAMOSO-Exercise-Table.png";
import NotAttempted from "../assets/other/not-attempted.svg";
import Incomplete from "../assets/other/incomplete.svg";
import Circle from "../assets/other/circle-green.png";
const getFAQs = () => {
  const data = [
    {
      number: "1.",
      question: "What is Jasperactive MOS Online?",
      answer:
        "Jasperactive MOS Online (or JMOSO) is a kinesthetic prescriptive learning product for Microsoft 365 that includes assessments, a project, and instructional learning for the student as well as auto-grading, teacher resources, and teacher reporting, accessed as a Microsoft Office Add-in from the Office Store.",
    },
    {
      number: "2.",
      question: "How do I access Jasperactive MOS Online?",
      answer:
        "Jasperactive MOS Online is an add-in found directly in the Office 365 web app. You can access the app by logging into Office 365, using one of the add-in methods (first time only), and then clicking on the orange Jasperactive logo on the Home ribbon. See our support article for step-by-step instructions. <a href='https://web.archive.org/web/20201127105815/https://jasperactive.com/faq/jasperactive-mos-online-faqs/' target='_blank' >Launching Jasperactive MOS Online in a web browser.</a>",
    },
    {
      number: "3.",
      question:
        "Are Jasperactive MOS Online (JMOSO) and Jasperactive MOS (JMOS) the same thing and interchangeable?",
      answer:
        "These two systems run on different databases and therefore are not interchangeable. As such, as a Teacher, you would launch JMOSO only as a student begins a particular Course from the beginning – i.e. a student would not transition from JMOS to JMOSO during course learning.",
    },
    {
      number: "4.",
      question: "How do I gain access to Jasperactive MOS Online (JMOSO)?",
      answers: [
        "FREE for existing customers with an “active” JMOS license, for the duration of the license.",
        "<a href='https://web.archive.org/web/20201127121632/https://jasperactive.com/global/'>Purchase<a>",
      ],
    },
    {
      number: "5.",
      question: "How do I use Jasperactive MOS Online (JMOSO) on my computer?",
      answers: [
        "Navigate to <a href='https://www.office.com' target='_blank'>www.office.com",
        "Sign in with either your Microsoft Account or Microsoft 365 Work/Student account",
        "On the left-hand side of the screen, select the Office application that you’re going to be learning (I.e. Word, Excel or PowerPoint)",
        "Open a Blank document, Spreadsheet or PowerPoint presentation",
        "Click Insert, click Add-ins, then search the Microsoft Store for Jasperactive",
        "Optionally, work with your IT team for Organizational Deployment via Microsoft 365 which installs the add-ins automatically",
      ],
    },
    {
      number: "6.",
      question: "Is there a support site for Jasperactive MOS Online (JMOSO)?",
      answer:
        "<a href='https://support.jasperactive.com' >https://support.jasperactive.com</a>",
    },
    {
      number: "7.",
      question: "What Microsoft accounts give me access to Office 365?",
      answer:
        "Either a personal Microsoft account or Microsoft 365 (formerly Office 365) Work/Student account will provide you access to the Web Browser versions of the Office applications",
    },
    {
      number: "8.",
      question:
        "What programs are available for learning in Jasperactive MOS Online (JMOSO)?",
      answers: [
        "Word, Excel and PowerPoint are currently available.",
        "Expert Levels for Word and Excel are due for release by the end of 2020.",
      ],
    },
    {
      number: "9.",
      question:
        "What languages is Jasperactive MOS Online (JMOSO) available in?",
      answers: ["English", "Spanish language is in development."],
    },
    {
      number: "10.",
      question:
        "How can I log into the Jasperactive MOS Online (JMOSO) program?",
      answers: [
        "Once the selected Jasperactive Add-in has been installed into the Office applications ribbon, click on it to launch the Jasperactive application.",
        "You will then be prompted to Get Started, click on the Get Started button and a dialog box will open prompting you to Register/Login.",
        "If you have not previously created an account, you can do so here by selecting Register Here OR use your Microsoft, Facebook or Google Plus accounts for single sign on.",
        "Microsoft 365 single sign is currently in development",
      ],
    },
    {
      number: "11.",
      question: "Do I have to take the Benchmark Assessment?",
      answers: [
        "To activate the Lessons, you do have to complete the Benchmark. Completing the Benchmark creates a prescriptive learning pathway that only includes the Lessons for which learning is required. Note: for those wishing to “bypass” this required element, instruct your student to launch the Benchmark then just click Skip All then Finish to submit results.",
        "Teachers can alter the Benchmark grade weighting from the Groups Manager dashboard",
      ],
    },
    {
      number: "12.",
      question: "How long is the Benchmark?",
      answers: [
        "The Benchmark in JMOSO defaults to a 30-minute timer to complete before it closes and prompts you to click the Finish button for scoring.",
        "Teachers can alter the Benchmark time from the Groups Manager dashboard",
      ],
    },
    {
      number: "13.",
      question: "Can the Benchmark be reset and retaken?",
      answer:
        "Yes. A Teacher can “reset” a Benchmark from the Teacher Panel Groups Manager dashboard",
    },
    {
      number: "14.",
      question: "Do I have to take the Lessons in order?",
      answer:
        "No. The Lessons are not numbered for that reason and do not require one lesson to be completed before the next is started. You can enter any Lesson you would like to complete in whatever order you would like to complete it. It is recommended that you start at the top and go through them in order as they are scaffolded for learning.",
    },
    {
      number: "15.",
      question:
        "What if the suggested time for a Lesson is longer than my class session?",
      answer:
        "Lessons are broken into Exercises and Tasks, which can be finished in less time.",
      img: Jmosonline,
    },
    {
      number: "16.",
      question: "Does JMOSO track student progress?",
      answer:
        "Yes. The teacher resources include a Teachers Resource Guide, Lesson Notes, Mapping for Basic and Advanced Exercises, Course Mapping to Certification Objectives, a set of student files, a set of completed files, and a sample syllabus. These can be found in the Teacher Panel.",
    },
    {
      number: "17.",
      question: "How do I know if I completed the exercise correctly?",
      answers:["Each exercise has a circle to the left of the exercise name to indicate the level of correctness."],
      answerswithlogo: [
        {
          img: NotAttempted,
          title: "Not yet attempted",
        },
        {
            img: Incomplete,
            title:"Incomplete – some of the tasks in the exercise were not completed or incorrectly completed."
        },
        {
            img: Circle ,
            title:"Completed correctly"
        },
      ],
    },
    {
      number: "18.",
      question: "How do I know the Lesson is completed?",
      answer:
        "The individual lesson exercise indicator will show a filled green dot.",
    },
    {
      number: "19.",
      question: "Can Lessons be retaken for a better score?",
      answer: "Yes, simply enter the Lessons and redo the exercises.",
    },
    {
      number: "20.",
      question: "What is a Basic exercise vs. an Advanced exercise?",
      answers: [
        "Exercises are created differently based on the commands that are available in the O365 web application. Not all commands exist in the O365 web app that are needed to teach to the certification exam.",
        "Basic exercises are exercises that are done live in the web-based application (LITA)",
        "Advanced exercises are click-through interactive exercises that are completed within the application and cannot be duplicated in the Benchmark, Create Project nor the Summary Assessment because the command does not actually exist in the O365 web app.",
      ],
    },
    {
      number: "21.",
      question: "Do I have to complete the Create Project?",
      answer:
        "No, it is supplementary learning but does cover valuable certification exam objectives and is recommended.",
    },
    {
      number: "22.",
      question: "What is the Create Project?",
      answers: [
        "A project that is embedded in the JMOSO program that comes with a predetermined scenario and tracks specific skills (measures content mastery) used to create the document. It is designed as a creativity-based project to demonstrate/apply what has been learned. The Create document can be completed on or offline and then uploaded for automatic system grading.",
        "If desired, the Create Project scenario can be customized by the teacher.",
      ],
    },
    {
      number: "23.",
      question: "What is the Summary Assessment?",
      answer:
        "The Summary Assessment assesses the skills the student has learned that exists in the O365 web app. For commands that do not exist in the O365 web app, students demonstrate mastery by completing Advanced, interactive exercises within the Lessons. The Summary Assessment allows the Learning Pathway to be measured.",
    },
    {
      number: "24.",
      question: "Is there a launch price/discount?",
      answer:
        "Yes. Please contact your <a href='https://web.archive.org/web/20201127121632/https://jasperactive.com/global/' target='_blank' >reseller.</a>",
    },
    {
      number: "25.",
      question:
        "How much total learning time should I estimate for Course completion?",
      answer: "Approximately 10 –15 hours. <a href='#14' >See here</a>",
    },
    {
      number: "26.",
      question:
        "If students do not have enough knowledge just from following the steps how else can they learn?",
      answer:
        "There is an eBook embedded at the beginning of every lesson. When you click on the eBook button it will auto-scroll you to the appropriate section.",
    },
    {
      number: "27.",
      question: "What are your Accommodations for students with disabilities?",
      answers: [
        "The Immersive Reader can be added as a Chrome extension which has many sources to help dyslexic and other students who struggle reading. Additionally, if you are using Edge you can click the ellipses (…) in the upper right corner and choose Read Aloud to have the text read to them.",
        "Teachers can alter the Benchmark and Summary Assessment time from the Groups Manager dashboard.",
      ],
    },
    {
      number: "28.",
      question:
        "Are there Teacher Resources available to aid in teaching these courses?",
      answer:
        "Yes. The teacher resources include a Teachers Resource Guide, Lesson Notes, Mapping for Basic and Advanced Exercises, Course Mapping to Certification Objectives, a set of student files, a set of completed files, and a sample syllabus. These can be found in the Teacher Panel.",
    },
    {
      number: "29.",
      question: "How do my students run a report to see their progress?",
      answer:
        "Student Course progress reporting, by the Student, is not yet available. At this time, if a student needs this information, a Teacher can run student data reports from their Teacher Panel, export the report, then filter and distribute the data appropriately.",
    },
    {
      number: "30.",
      question:
        "Where is my Jasperactive icon in PowerPoint? I do not see it on the ribbon.",
      answer:
        "The JMOSO program icon to activate the JMOSO program pane is only found on the Home tab. In PowerPoint web app there are too many commands on the ribbon for the JMOSO icon to be visible. Use the ellipses (…) at the far-right side of the pane to show more commands and you will see the JMOSO program icon at the bottom.",
    },
    {
      number: "31.",
      question:
        "Where are the pre-Lesson Quizzes that students had to complete in the traditional Jasperactive system?",
      answer:
        "Quiz activities are not currently available. If this is important to you, simply email <a style='text-decoration-line:underline'>feedback@jasperactive.com</a> or use the Feedback option in the Web app located at the bottom of the Jasperactive Getting Started pane.",
    },
    {
      number: "32.",
      question:
        "Why does the START or ENTER button not work or why does it just spin when I click it?",
      answer:
        "You may have timed out. Refresh your browser or close the program you are in and launch it again, forcing a new login to Jasperactive.",
    },
    {
      number: "33.",
      question: "What are the bandwidth requirements?",
      answer:
        "Minimum bandwidth requirements would be 512 Kb/s per machine, although higher speeds are recommended.",
    },
  ];
  return data;
};

export default getFAQs;
