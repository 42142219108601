import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Link, Typography } from '@material-ui/core';
import { theme } from "../../theme";
const useStyles = makeStyles({
    root: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(2),
            width: "200px",
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
            width: "300px",
        },
        border: "1px solid #EBECEE",
        borderRadius: "4px",
        padding: "20px 15px",
        "& a": {
            color: "#2F80ED"
        }
    },
});

const LinkBox = ({ links }) => {
    const classes = useStyles();
    const openLink = (url) => {
        window.open(url, "_blank");
    };
    
    return (
        <Box className={classes.root}>
            <Box>
                <Typography variant="body1">
                    Download Links
                </Typography>
            </Box>
            {
                links.filter(x => !x.isHidden).map((item, index) => (
                    <Box key={index} mt={3}>
                        <Typography variant="subtitle1">
                            <Link href="#" underline="always" onClick={() => { openLink(item.url) }}>
                                {item.text}
                            </Link>
                        </Typography>
                    </Box>
                ))
            }
        </Box>
    );
};



export default LinkBox;