import {makeStyles} from '@material-ui/core/styles'
import {theme} from '../../theme'

const styles = makeStyles({

    contactUsSection:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        marginTop:theme.spacing(16)
    },

    contactUsSectionTitle:{
        ...theme.typography.h2,
        color:theme.palette.grey[500],
        marginBottom:theme.spacing(4),
        fontWeight:500
    },
    contactusSubtitle:{
        ...theme.typography.h3,
        color:theme.palette.grey[500],
        marginTop:theme.spacing(2)
        
    },
    contactForm:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-evenly",
        marginBottom:theme.spacing(2)
    },
     
    inputField:{
        marginTop:theme.spacing(2),
        height:theme.spacing(7),
        paddingLeft:theme.spacing(2),
        backgroundColor:theme.palette.grey[100]
    },

    messageField:{
        marginTop:theme.spacing(2),
        paddingLeft:theme.spacing(2),
        backgroundColor:theme.palette.grey[100]
        
    },
   
    submitBtn:{
        
        ...theme.typography.h4,
        marginTop:theme.spacing(2),
        width:"fit-content",
        alignSelf:"flex-end",
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.common.white,
        textTransform:"uppercase",
        '&:hover':{
            backgroundColor:theme.palette.primary.main
        }

    }

})


export default styles;