import { Typography, useMediaQuery } from '@material-ui/core';
import React, {useEffect} from 'react'
import styles from './style'
import ContactLogo from '../../assets/logos/salesteam/contact-icon.svg'
import PurchaseLog from '../../assets/logos/salesteam/purchase-icon.svg'
import SuccessLogo from '../../assets/logos/salesteam/success-icon.svg'
import TrainingLogo from '../../assets/logos/salesteam/training-icon.svg'
import ProgressLogo from '../../assets/logos/salesteam/progess-icon.svg'
import StudentEngagementLogo from '../../assets/logos/salesteam/student-icon.svg'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import BannerLogo from '../../assets/logos/salesteam/sales_banner.png'
import BannerLogo1 from '../../assets/logos/salesteam/sales_banner_2.png'

import IndividualPathway from '../Home/LearningPathway/IndividualPathway'
import ContactUs from '../ContactUs'
import { theme } from '../../theme'
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll'

const Pricing = () => {

    const classes = new styles();
    const isMathced = useMediaQuery(theme.breakpoints.down('xs'));
    const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    

    useEffect(() => {
        if(location.hash){
            scroller.scrollTo("contactus", {
                smooth:true,
            })
        }
    }, [location])


    const renderSalesData = () => {
        const data = [
            {
                logo: ContactLogo,
                title: "Contact Us",
                description: "Upon being contacted, our Sales team reaches out and understands the customer needs. Our sales channel includes partners all over the world."
            },
            {
                logo: PurchaseLog,
                title: "Quote & Purchase",
                description: "We have various options aligned to the customer’s needs so the customer can select the preferred option and complete their purchase."
            },
            {
                logo: SuccessLogo,
                title: "Success Planning",
                description: "After subscribing to a specific package, customers are onboarded by our Customer Success team and then guided to set their success metrics for the upcoming year."
            },
            {
                logo: TrainingLogo,
                title: "Educator Training",
                description: "Customers can contact their Customer Success team for training and deployment assistance."
            },
            {
                logo: StudentEngagementLogo,
                title: "Student Engagement",
                description: "Now it's time for your students to get started with their learning and preparation for the many certification programs that our learning solutions map to. "
            },
            {
                logo: ProgressLogo,
                title: "Progress & Checkup",
                description: "We are with you every step of the way! Checkups are setup with our Customer Success team to gauge annual performance based on user activity and success metrics."
            },
        ]
        return data;
    }

    return (
        <div className={classes.container}   >
            <Typography className={classes.title} >
                We offer curriculums, certifications and end to end education solutions.
            </Typography>
            <div className={classes.salesTeamPath} >
                <Typography className={classes.salesTitle} >
                    Our prospective customers can expect the following after contacting us:
                </Typography>
                <div className={classes.salesIndividual} >
                    {renderSalesData().map((value, i) => {
                        return <React.Fragment key={i} >
                            <IndividualPathway logo={value.logo} title={value.title} description={value.description} />
                            {renderSalesData().length - 1 !== i ?
                                isMathced ? <ArrowDownwardIcon style={{ alignSelf: "center", color: "#717073" }} /> :
                                    <ArrowRightAltIcon style={{ alignSelf: "center", color: "#717073" }} />
                                : ""}
                        </React.Fragment>
                    })}
                </div>


            </div>
            <div className={classes.joinSellerTeam} >
                <div className={classes.joinLeftSection} >
                    <Typography style={{ ...theme.typography.h2, color: theme.palette.grey[500], marginBottom:theme.spacing(4), fontWeight:500 }} >
                        You can join our reseller team!
                    </Typography>
                    <Typography className={classes.joinIndividualText} >
                        We find that business is more fun with partnerships and we are always looking to add to our global channel.  We do have many partnerships in place, but we always try and include firms in local countries and geographies who can help bring Jasperactive to local economies.
                    </Typography>
                    <Typography className={classes.joinIndividualText} >
                        We travel around the planet every year and make a point of being able to meet our partners in person at least once a year.
                    </Typography>
                    <Typography className={classes.joinIndividualText} >
                        Reseller Benefits include:
                    </Typography>
                    <ul className={classes.joinIndividualText} >
                        <li>Shared Margin</li>
                        <li>Customizable Marketing Library</li>
                        <li>Translation support</li>
                        <li>Sales Training</li>
                        <li>Marketing Training</li>
                        <li>Collaboration on social media</li>
                    </ul>

                </div>
                <img
                    src={BannerLogo1}
                    alt="salesbanner1"
                    style={{height:"500px", marginRight: !isTablet ? 16 : 0, marginBottom: isTablet ? 16 : 0  }}
                />
                <img
                    src={BannerLogo}
                    alt="salesbanner"
                    style={{height:"500px"}}
                />
                
            </div>
            <ContactUs />
        </div>
    )
}


export default Pricing;
