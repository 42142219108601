import {makeStyles} from "@material-ui/core/styles"
import {theme} from '../../theme'


const styles = makeStyles({
    container:{
        display:"flex",
        flexDirection:"column"
        
    },
    brandSection:{
        display:"flex",
        justifyContent:"space-evenly",
        marginTop:theme.spacing(7),
        [theme.breakpoints.down("md")]:{
            flexDirection:"column"
        },
        [theme.breakpoints.down("sm")]:{
            flexDirection:"column",
            marginTop:theme.spacing(4)
        }
    },
    brandLeft:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-evenly",
        width:"50%",
        [theme.breakpoints.down("md")]:{
            width:"100%"
        }
    },
    brandTitle:{
        fontSize:"62px",
        fontWeight:"500",
        lineHeight:"72px",
        marginBottom:theme.spacing(3),
        color:theme.palette.grey[400],
        [theme.breakpoints.down("md")]:{
            fontSize:"46px",
        },
        [theme.breakpoints.down('xs')]:{
            fontSize:"46px",
            lineHeight:"40px"
        }
    },
    brandDesc:{
        ...theme.typography.h3,
        color:theme.palette.grey[500],
        [theme.breakpoints.down("md")]:{
            fontSize:"18px"
        },
        [theme.breakpoints.down("xs")]:{
            ...theme.typography.body2,
            marginTop:theme.spacing(4)
        },
    },
    buttonContainer:{
        display:"flex",
        justifyContent:"flex-start",
        marginTop:theme.spacing(2)
    },
    exploreButton:{
        fontSize:"16px",
        lineHeight:"24px",
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.common.white,
        '&:hover':{
            backgroundColor:theme.palette.primary.main
        },
        paddingLeft: theme.spacing(4),
        paddingRight:theme.spacing(4),
        marginRight:'5%',
        [theme.breakpoints.down("xs")]:{
            fontSize:"13px",
            lineHeight:"20px",
            marginRight:theme.spacing(1),
            color:theme.palette.common.white,
            paddingTop:theme.spacing(1),
            paddingBottom:theme.spacing(1),
            paddingLeft:theme.spacing(2),
            paddingRight:theme.spacing(2)
        },
    },
    requestDemoButton:{
        fontSize:"16px",
        lineHeight:"24px",
        color:theme.palette.primary.main,
        backgroundColor:theme.palette.common.white,
        border:"1px solid #BF1212",
        paddingLeft: theme.spacing(4),
        paddingRight:theme.spacing(4),
        '&:hover':{
            backgroundColor:theme.palette.common.white
        },
        marginRight:"5%",
        [theme.breakpoints.down("xs")]:{
            fontSize:"13px",
            lineHeight:"20px",
            marginRight:"0px",
            paddingTop:theme.spacing(1),
            paddingBottom:theme.spacing(1),
            paddingLeft:theme.spacing(2),
            paddingRight:theme.spacing(2)
        },
    },
    bannerImage:{
        width:"50%",
        [theme.breakpoints.down("md")]:{
            alignSelf:"center"
        },[theme.breakpoints.down("sm")]:{
            width:"80%",
            marginTop:theme.spacing(2)
        }
    },
    iconDiv:{
        marginRight:theme.spacing(2),
        display:"flex",
        justifyContent:"center",
        alignItems:'center',
        '&:hover':{
            cursor:"pointer"
        },
        [theme.breakpoints.down('xs')]:{
            display:"none"
        }
    }
})


export default styles;