import {makeStyles} from '@material-ui/core/styles'
import {theme} from '../../theme'

const styles = makeStyles({
    container:{
        display:"flex",
        marginTop:theme.spacing(2),
        marginBottom:theme.spacing(4),
        marginLeft:theme.spacing(3)
    },
    links:{
        ...theme.typography.subtitle1,
        color:theme.palette.primary.main,
        textDecoration:"none"
        
    },
    lastLink:{
        fontSize:"13px",
        fontWeight:"600",
        lineHeight:"20px"
    },
    backarrow:{
        color:theme.palette.primary.main,
        marginRight:theme.spacing(2),
        '&:hover':{
            cursor:"pointer"
        }
    },
})


export default styles;