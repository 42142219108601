import {makeStyles} from '@material-ui/core/styles'
import {theme} from '../../../../theme'
  
const styles = makeStyles({
    cardConatiner:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        minWidth:"30%",
        maxWidth:"30%",
        minHeight:"30%",
        marginRight:"40px",
        marginTop:theme.spacing(4),
        '&:hover':{
            cursor:"pointer"
        },
        [theme.breakpoints.down("md")]:{
            minWidth:"45%",
            maxWidth:"45%"
        },[theme.breakpoints.down("sm")]:{
            minWidth:"43%",
            maxWidth:"43%"
        },[theme.breakpoints.down("xs")]:{
            minWidth:"100%",
            maxWidth:"100%"
        }
    },
    topContent:{
        display:"flex",
        justifyContent:"flex-start",
        alignItems:"center"
    },
    title:{
        ...theme.typography.h3,
        color:theme.palette.grey[500],
        [theme.breakpoints.down("md")]:{
            fontSize:"18px"
        }
    },
    description:{
        ...theme.typography.body1,
        color:theme.palette.grey[400]
    },
    learMore:{
        display:"flex",
        justifyContent:"flex-end",        
    }
})

export default styles;