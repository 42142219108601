import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { useAuthentication } from '../../providers/AuthenticationProvider'


const  PrivateRoutes = ({component: Component, location, ...restProps }) => {
    
    const  { user } = useAuthentication();
    return (
        <Route
          render={props => {
            if (user) return <Component {...props} />;
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location },
                }}
              />
            );
          }}
          {...restProps}
        />
      );
}


export default PrivateRoutes;
