import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../theme";

const styles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginTop: theme.spacing(7),
  },
  topcontainer: {
    display: "flex",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  videoSection: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
  topLeftcontainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: theme.spacing(1),
    },
  },
  logoSection: {
    display: "flex",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  title: {
    fontSize: "40px",
    lineHeight: "77px",
    color: theme.palette.grey[400],
    [theme.breakpoints.down("xs")]: {
      fontSize: "32px",
      lineHeight: "40px",
    },
  },
  description: {
    ...theme.typography.h3,
    color: theme.palette.grey[500],
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  buttonSection: {
    display: "flex",
    marginTop: theme.spacing(2),
    justifyContent: "flex-start",
  },
  goToButton: {
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.grey[100],
    minWidth: "30%",
    marginRight: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  requestDemoButton: {
    fontSize: "16px",
    lineHeight: "24px",
    backgroundColor: theme.palette.common.white,
    minWidth: "30%",
    marginRight: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
  arrow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  productPartnerSection: {
    marginTop: theme.spacing(16),
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  productPartnerTitle: {
    ...theme.typography.h3,
    fontWeight: 600,
    color: theme.palette.grey[400],
    marginRight: theme.spacing(2),
  },
  courseSection: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(16),
  },
  availableCourse: {
    ...theme.typography.h3,
    fontWeight: 600,
    //marginBottom: theme.spacing(4),
    color: theme.palette.grey[400],
  },
  courses: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  individualCourse: {
    display: "flex",
    flexDirection: "column",
    minWidth: "23%",
    maxWidth: "25%",
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(3),
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "45%",
      minWidth: "45%",
      marginRight: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "80%",
      minWidth: "80%",
      marginRight: "0px",
    },
  },
  coversLists: {
    display: "flex",
    flexDirection: "column",
  },
  courseTitle: {
    ...theme.typography.h4,
    fontWeight: 500,
    color: theme.palette.grey[500],
  },
  coversTitle: {
    ...theme.typography.subtitle1,
    fontWeight: 500,
    color: theme.palette.grey[500],
  },
  faqSection:{
    display:"flex",
    flexDirection:"column",
    marginTop:theme.spacing(16)
  },
  faqTitle: {
    ...theme.typography.h2,
    color: theme.palette.grey[400],
    marginBottom:theme.spacing(2)
  },
  otherProductsSection: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(16),
  },
  otherProductTitle: {
    ...theme.typography.h3,
    fontWeight: 600,
    color: theme.palette.grey[400],
  },
  otherProductSection: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xm")]: {
      flexDirection: "column",
    },
  },
  resources_jac: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2em',
    gap: '1.5em'
  },
  resources_jac_courses: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '1.5em'
  },
  resources_jac_card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      minWidth: "100%",
      marginRight: "0px",
    },
  },
  resources_jac_card_content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
    width: '100%'
  },
});

export default styles;
