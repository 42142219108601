import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import styles from "./styles";
import { Collapse } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import parse from "html-react-parser";

const FAQ = ({
  number,
  question,
  answer,
  answers,
  img,
  id,
  answerwithlogo,
  handleFAQ,
  showFAQ
}) => {
  const classes = styles();
  const [showAnswer, setshowAnswer] = useState(false);

  const handlesetShow = () => {
      setshowAnswer(!showAnswer);
  }

  return (
    <div className={classes.container} id={id} >
      <div style={{ display: "flex", flexDirection: "column" }} onClick={ handleFAQ ? handleFAQ : handlesetShow} >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography className={classes.question}>
            {number} {question}
          </Typography>
          {showAnswer ? (
            <ArrowDropUpIcon
              color="primary"
              onClick={handlesetShow}
            />
          ) : (
            <ArrowDropDownIcon
              onClick={handlesetShow}
            />
          )}
        </div>
        <Collapse in={ id === 14 ? showFAQ : showAnswer}>
          {answer !== undefined ? (
            <Typography className={classes.answer}>{parse(answer)}</Typography>
          ) : (
            ""
          )}
          {answers !== undefined ? (
            <ul className={classes.answer}>
              {answers.map((indianswer, index) => {
                return <li key={index}>{parse(indianswer)}</li>;
              })}
            </ul>
          ) : (
            ""
          )}
          {img !== undefined ? (
            <img src={img} alt="jmosonline" width="100%" />
          ) : (
            ""
          )}
          {answerwithlogo !== undefined ? (
            <ul className={classes.answer}>
              {answerwithlogo.map((data, index) => {
                return (
                  <li
                    key={index}
                    style={{
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display:"flex", justifyContent:"flex-start" , alignItems:"center" }} >
                      <img src={data.img} alt="logo" style={{marginRight:8 }} />
                      {data.title}
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            ""
          )}
        </Collapse>
      </div>
    </div>
  );
};

export default FAQ;
