import {makeStyles} from '@material-ui/core/styles'
import {theme} from '../../../theme'

const styles = makeStyles({
    container:{
        display:"flex",
        flexDirection:"column",
        marginTop:theme.spacing(16),

    },
    headingSection:{
        display:"flex",
        marginBottom:theme.spacing(3),
        alignItems:"center"

    },
    heading:{
        fontSize:"54px",
        lineHeight:"64px",
        marginLeft:theme.spacing(3),
        color:theme.palette.grey[400],
        fontWeight:"600",
        [theme.breakpoints.down('xs')]:{
            fontSize:"24px",
            lineHeight:"30px"
        }
    },
    testimonialSection:{
        display:"flex",
        marginTop:theme.spacing(3),
        marginBottom:theme.spacing(3),
        [theme.breakpoints.down('xs')]:{
            flexDirection:"column"
        }
    },
    individualTestimonial:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        maxWidth:"25%",
        padding:"16px",
        marginRight:theme.spacing(1),
        [theme.breakpoints.down('xs')]:{
            maxWidth:"100%",
            marginTop:theme.spacing(1),
            marginBottom:theme.spacing(1)
        }
        
    },
    review:{
        ...theme.typography.subtitle2,
        marginBottom:theme.spacing(3),
        fontStyle:"italic"
    },
    reviewBy:{
        ...theme.typography.subtitle1
    }
})

export default styles;