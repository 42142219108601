import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import styles from "./styles";
import { Box, Typography } from "@material-ui/core";
import { theme } from "../../theme";
import { scroller } from "react-scroll";

const PrivacyNotice = () => {
  const { pathname, hash } = useLocation();
  const classes = styles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  useEffect(() => {
    if(hash === "#dpa"){
      scroller.scrollTo("dpa", {
        smooth:true
      })
    }
  }, [hash])

  return (
    <Box display="flex" flexDirection="column">
      <Typography className={classes.privacyTitle}>Privacy</Typography>
      <Typography className={classes.privacyDesc}>
        CCI Learning Inc., and its subsidiaries (Jasper Learning Inc., CCI
        Learning Solutions Inc. and CCI Learning Solutions Inc. (Arizona),
        Collectively, “CCI” or “we” or “our”), take privacy, systems integrity
        and security very seriously and are committed to the highest standards
        of privacy and data security and compliance with standards, laws and our
        contractual obligations.
      </Typography>
      <Typography className={classes.privacyDesc}>
        At CCI we follow industry standard policies and local privacy and
        security laws and regulations in the countries we serve. Privacy and
        security of our customers’, our staff and end-users data (especially
        Personally Identifiable Information) and ability to access and use our
        services in a secure environment is paramount to us.
      </Typography>
      <Typography className={classes.privacyDesc}>
        CCI and our Services collect, hold, manage and process data including
        personal information in the capacity of a Controller or Processor as
        follows:
      </Typography>
      <Box display="flex">
        <Typography className={classes.privacyHeader}>Controller</Typography>
        <Typography
          className={classes.privacyHeader}
          style={{ marginLeft: theme.spacing(3) }}
        >
          An entity that determines the purposes and means of processing of
          personal data.
        </Typography>
      </Box>
      <Typography className={classes.privacyDesc}>
        In relationship to our web sites or our dealing with customer sales and
        fulfillment, we act as Controller and are solely responsible for
        determining the purposes and means of the processing of personal data
        and are accountable to such persons for which we have asked, accepted or
        hold personal data.  Our policies and practices governing our collection
        and maintenance of personal data as Controller are set out in our{" "}
        <Link to="/statement-of-privacy" >Privacy Policy</Link>
      </Typography>
      <Box display="flex" mt={2}>
        <Typography className={classes.privacyHeader}>Processor</Typography>
        <Typography
          className={classes.privacyHeader}
          style={{ marginLeft: theme.spacing(3) }}
        >
          An entity that processes personal data based on the instructions of a
          Controller
        </Typography>
      </Box>
      <Typography className={classes.privacyDesc}>
        In relationship to our Software Services such as Jasperactive, we are
        considered a Processor and we hold or process personal data solely on
        behalf of and at the direction of the Services’ controller pursuant to a
        data processing agreement (DPA).  The DPA is a part of our Master
        Services Agreement and included therewith.{" "}
      </Typography>
      <Typography className={classes.privacyDesc}>
        Under a DPA with customers who purchase our Jasperactive Service, we
        agree to host and manage our Jasperactive Service on behalf of the
        customer who is considered the Controller of personal information and
        shall so determine in accordance with their own Privacy Policies, the
        personal information they record therein and the policies related
        thereto. Under a DPA, CCI pledges to only process or host as our
        customer directs and to maintain security and access to the Service for
        our customer and any information they so provide to the Service in
        accordance with our Master Services Agreement.
      </Typography>
      <Box id="dpa" >
        <Typography className={classes.privacyHeader}>
          Data Processing Agreements{" "}
        </Typography>
        <ul>
          <li>
            EU General Data Protection Regulation{" "}
            <Link to="/data-processing-agreement" >Data Processing Agreement</Link>
          </li>
          <li>
            Washington State –{" "}
            <Link to="/student-data-privacy-agreement" >Student Data Privacy Agreement offer (09 01 2019)</Link>{" "}
          </li>
        </ul>
      </Box>
      <Typography className={classes.privacyHeader}>
        Student Data Privacy Pledge
      </Typography>
      <Typography>
        At CCI, our business is focused on providing superior learning products
        to our customers who are educational or training institutions, including
        corporate and government departments that teach or train students. We
        support educators and students and their educational needs and their
        schools’ or educational institutions’ operations and have understand the
        responsibility for maintaining the strictest policies, processes for the
        protection of Student Personal Data. As such in our operations,
        agreements and policies we undertake to process Student Data in
        accordance with our <Link to="/student-privacy-pledge" >Student Privacy Pledge</Link>.
      </Typography>
    </Box>
  );
};

export default PrivacyNotice;
