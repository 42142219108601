import { makeStyles } from '@material-ui/core/styles'
import { theme } from '../../theme'

const styles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        marginTop:theme.spacing(7),
        flex: 1
    },
    title: {
        ...theme.typography.h2,
        color: theme.palette.grey[500],
        marginBottom:theme.spacing(2),
        fontWeight:500
    },
    salesTeamPath: {
        display: "flex",
        flexDirection: "column",
        marginTop:theme.spacing(6)

    },
    salesTitle: {
        ...theme.typography.h3,
        color: theme.palette.grey[400],
        marginBottom:theme.spacing(2)
    },
    salesIndividual: {
        display: "flex",
        justifyContent:"flex-start",
        flexWrap:"wrap",
        [theme.breakpoints.down('xs')]:{
            flexDirection:"column"
        }
    },
    joinSellerTeam:{
        display:"flex",
        justifyContent:"space-between",
        marginTop:theme.spacing(16),
        [theme.breakpoints.down('sm')]:{
            flexDirection:"column"
        }

    },
    joinLeftSection:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        marginRight:theme.spacing(6),
        [theme.breakpoints.down('xs')]:{
            marginRight:"0px"
        }
        
    },
    joinIndividualText:{
        ...theme.typography.body1,
        color:theme.palette.grey[400]
    },
    
})


export default styles;