import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        minHeight: "90vh"
    }
})

export default styles;