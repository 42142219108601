import React, { useState, useEffect } from "react";
import { useHistory, useLocation} from 'react-router-dom';
import { Box, IconButton, Button, Typography, useMediaQuery } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { pdfjs } from 'react-pdf';
import StudentAgreementPDF from "../../assets/other/student-data-privacy-agreement.pdf";
import styles from "./styles";
import { theme } from "../../theme";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const StudentDataPrivacy = () => {
  const classes = styles();
  const history = useHistory();
  const pathname = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePdfNextPage = () => {
    if(pageNumber < numPages){
      setPageNumber(pageNumber + 1);
    }
  }

  const handlePdfPrevPage = () => {
    if(pageNumber > 1){
      setPageNumber(pageNumber - 1);
    }
  }

  return (
    <Box display="flex" flexDirection="column" alignSelf="center">
      <IconButton style={{ alignSelf: "baseline" }} onClick={() => { history.goBack(); }} >
        <KeyboardBackspaceIcon color="primary" />
      </IconButton>
      <Document
        className={classes.pdf}
        file={StudentAgreementPDF}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} width={isMobile ? 400 : ""} />
      </Document>
      <Box
        mt={2}
        alignSelf="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Button disabled={pageNumber === 1} color="primary" variant="contained" style={{ marginRight: 8 }} onClick={handlePdfPrevPage} >
          Prev
        </Button>
        <Typography>
          {pageNumber} / {numPages}
        </Typography>
        <Button color="primary" disabled={pageNumber === numPages} variant="contained" style={{ marginLeft: 8 }} onClick={handlePdfNextPage} >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default StudentDataPrivacy;
