import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { theme } from "../../theme";
import ReactPlayer from 'react-player/file'
import Step from './Step';
import LinkBox from './LinkBox';
import axios from 'axios';
import { Loading } from '../ui';

const useStyles = makeStyles({
    stepContainer: {
        display: "flex",
        flexDirection: "column",
        width: "90%"
    },
    stepText: {
        marginLeft: theme.spacing(2),
        width: "90%"
    }
});

const Steps = ({ operatingSystem }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [info, setInfo] = React.useState({});
    const [error, setError] = React.useState(null);
    const classes = useStyles();
    const links1 = [
        { text: "Latest version of the extension", url: info?.downloadUrl, isHidden: false },
        { text: "Anastasiy’s Extension Manager ", url: "https://install.anastasiy.com/", isHidden: false },
        { text: "Troubleshooting", url: "https://addin-resources.jasperactive.com/jasperactive-adobe/Guides/Troubleshooting.pdf", isHidden: operatingSystem !== "mac" },
    ];

    React.useEffect(() => {
        const getInfo = async () => {
            try {
                const response = await axios.get(`/Download/GetFileInfo?appName=adobe`);
                setIsLoading(false);
                setInfo(response.data);
            } catch (error) {
                setIsLoading(false);
                setError(error);
            };
        }

        getInfo();
    }, []);

    const getVideoUrl = () => {
        switch (operatingSystem) {
            case "windows":
                return "https://addin-resources.jasperactive.com/jasperactive-adobe/windows_installation_guide.mp4";
            case "mac":
                return "https://addin-resources.jasperactive.com/jasperactive-adobe/mac_installation_guide.mp4";
            default:
                return "Intallations guide";
        }
    };

    if (isLoading) {
        return <Loading />
    }

    if (error) {
        return (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{}} mt={5}>
                <Typography component="h1" className={classes.brandTitle} >
                    Something went wrong!
                </Typography>
                <Typography component="p" className={classes.brandDesc}>
                    {error.message}
                </Typography>
            </Box>
        );
    }

    return (
        <React.Fragment>
            <Box className={classes.stepContainer}>
                <ReactPlayer url={getVideoUrl()} controls={true} width="100%" config={{ file: { attributes: { controlsList: 'nodownload' } } }} />
                <Step step={1}>
                    <Typography className={classes.stepText}>
                        Download the latest version of the extension.<br />
                        (Link to the right).
                    </Typography>
                </Step>
                <Step step={2}>
                    <Typography className={classes.stepText}>
                        To install the <b>Jasperactive extension</b>, you will use the <b>Anastasiy’s<br />
                            Extension Manager</b> program. (Link to the right).
                    </Typography>
                </Step>
                <Step step={3}>
                    <Typography className={classes.stepText}>
                        Open the ExtensionManager and follow the wizard's instructions to<br />
                        complete the installation.
                    </Typography>
                </Step>
                <Step step={4}>
                    <Typography className={classes.stepText}>
                        Select <b>Photoshop</b> from the <b>Adobe Products</b> list and click the Install<br />
                        button.
                    </Typography>
                </Step>
                <Step step={5}>
                    <Typography className={classes.stepText}>
                        Grant the administrator permissions to complete the installation.
                    </Typography>
                </Step>
                <Step step={6}>
                    <Typography className={classes.stepText}>
                        Open Adobe <b>Photoshop</b> and go to the menu<br />
                        <b>Window &gt; Extensions &gt; Jasperactive</b> to open the extension.
                    </Typography>
                </Step>
            </Box>
            <LinkBox links={links1} />
        </React.Fragment>
    );
};

export default Steps;