import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { theme } from "../../theme";
import WarningIcon from '../../assets/other/warning.svg';
import LinkBox from "./LinkBox";

const useStyles = makeStyles({
    icon: {
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
        },
    },
    text: {
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
        },
    },
    listStyle: {
        listStyleType: "lower-latin",
        fontWeight: "bold",
        "& li span": {
            fontWeight: "normal"
        }
    },
});

const Requirements = () => {
    const classes = useStyles();
    const links1 = [
        { text: "Photoshop Installation Guide", url: "https://helpx.adobe.com/creative-cloud/help/download-install-Photoshop.html" },
        { text: "Instructions to set Photoshop language", url: "https://helpx.adobe.com/creative-cloud/help/change-install-language.html" },
    ];
    return (
        <React.Fragment>
            <Box className={classes.icon}>
                <img src={WarningIcon} alt="Warning" />
            </Box>
            <Box className={classes.text}>
                <Typography variant="subtitle2">
                    <b>Before installing the Jasperactive extension</b>, check that you meet the following requirements:<br />
                    <ul className={classes.listStyle}>
                        <li><span>You have <b>administrator privileges</b> to do the installation.</span></li>
                        <li>
                            <span>You have one of the following versions of Photoshop installed.</span>
                            <ul>
                                <li>Adobe Photoshop 2020.</li>
                                <li>Adobe Photoshop 2021.</li>
                            </ul>
                        </li>
                        <li><span>The installation language is <b>English (North America).</b></span></li>
                    </ul>
                </Typography>
            </Box>
            <LinkBox links={links1} />
        </React.Fragment>
    );
};

export default Requirements;