import React from "react";
import { Box, Typography } from "@material-ui/core";
import styles from "./styles";

const EndUserLicenseAgreement = () => {
  const classes = styles();

  return (
    <Box display="flex" flexDirection="column">
      <Typography className={classes.privacyTitle}>
        JASPERACTIVE&trade; END-USER LICENSE AGREEMENT{" "}
      </Typography>
      <Typography className={classes.privacyDesc}>
        YOU ARE REQUIRED TO ACKNOWLEDGE THAT YOU HAVE READ AND ACCEPTED THE
        TERMS AND CONDITIONS OF THIS END-USER LICENSE AGREEMENT (“EULA”) BEFORE
        YOU MAY CONTINUE INSTALLING JASPERACTIVE™ ON YOUR SYSTEM OR NETWORK.{" "}
      </Typography>
      <ol>
        <li className={classes.privacyDesc}>
          Scope of this Agreement. This EULA constitutes a binding legal
          agreement between you and Jasper Learning Inc., developer and owner of
          the Jasperactive™ learning software product (“Jasperactive”), in
          respect of your use of Jasperactive. By accepting this EULA where
          indicated below and installing Jasperactive™ on your system or
          systems, you agree to be bound by the terms of this EULA which
          represents the entire agreement between us in respect of your license
          of Jasperactive and supersedes any prior agreement, representation or
          understanding between yourself and Jasper Learning Inc. or any of its
          distributors, agents or vendors. If you do not agree to the terms of
          this EULA you may not install or use Jasperactive.{" "}
        </li>
        <li className={classes.privacyDesc}>
          Grant of License. If accepted by you, this EULA represents the grant
          by Jasper Learning Inc. of a license for you or the organization you
          represent to use Jasperactive solely for purposes of training and
          private study. It does not in any way constitute a transfer of
          ownership of or copyright in Jasperactive or any part thereof to you
          or to any other person or organization.
        </li>
        <li className={classes.privacyDesc}>
          Ownership and Copyright. You hereby acknowledge that Jasperactive is
          exclusively owned by Jasper Learning Inc. and that all of the displays
          and content it contains are owned and copyrighted either by Jasper
          Learning Inc. or other copyright holders acknowledged within
          Jasperactive. You will not make any claim to or representation of
          ownership of Jasperactive or any part thereof. You further acknowledge
          that Jasperactive is protected pursuant to copyright laws and
          international copyright treaties and other intellectual property laws
          and treaties.
        </li>
        <li className={classes.privacyDesc}>
          Jasperactive Installations. If you are an organization that has
          purchased a multi-user site license for Jasperactive, you may install
          it on any number of computers including students’ personal home
          computers and devices. If you have purchased an individual license for
          Jasperactive you may install it on your own computer. Each licensee
          and licensee organization is responsible to ensure its registration
          information within the Jasperactive system is correct. All computers
          on which Jasperactive is installed must be equipped with validly
          purchased copies of the operating software compatible with the version
          of Jasperactive that has been installed. In an effort to constantly
          improve, the Jasperactive software is updated periodically.
        </li>
        <li className={classes.privacyDesc}>
          Prohibitions on Use. You may not copy, modify or create a derivative
          or collective work or compilation of Jasperactive or any part thereof
          or remove, delete, or reverse engineer, decompile or otherwise attempt
          to extract the code within Jasperactive, and you are prohibited from
          attempting to use or gain unauthorized access to Jasper Learning Inc.
          or any third party networks or equipment or attempt to probe, scan or
          test the vulnerability of Jasperactive or any system used for its
          operation.
        </li>
        <li className={classes.privacyDesc}>
          Suspension of Services. Jasper Learning Inc. may suspend, terminate,
          withdraw or discontinue all or any part of the Jasperactive services
          to you or one or more of your student users on receipt of a subpoena
          or legally enforceable request from a legitimate government or law
          enforcement agency or if Jasper Learning Inc. believes acting
          reasonably and in its sole discretion that you or your users have
          breached any term of this agreement or are involved in any fraudulent,
          misleading of illegal activity.
        </li>
        <li className={classes.privacyDesc}>
          Term. The license granted under the terms of this EULA will commence
          on the date you or the organization you represent first purchases this
          license, and will terminate automatically one year from this date of
          commencement, or at such earlier time as may be determined by Jasper
          Learning Inc. in the event of any breach of the terms of this EULA by
          the licensee.
        </li>
        <li className={classes.privacyDesc}>
          NO WARRANTIES. YOU HEREBY ACKNOWLEDGE AND AGREE THAT (A) JASPER
          LEARNING INC. MAKES NO WARRANTY CONCERNING JASPERACTIVE AND HEREBY
          DISCLAIMS ALL WARRANTIES WHETHER EXPRESS OR IMPLIED WITH RESPECT
          THERETO, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES IN
          RESPECT OF MERCHANTABILITY, ERRORS OR OMISSIONS, FITNESS FOR A
          PARTICULAR PURPOSE, OR INFRINGEMENTS OF RIGHTS OF PUBLICITY OR
          PRIVACY, TRADEMARK RIGHTS, DEFAMATION, BUSINESS INTERRUPTION, PERSONAL
          INJURY OR THE DISCLOSURE OF CONFIDENTIAL INFORMATION INDIRECT, OR ANY
          HIDDEN OR LATENT DEFECT, DISABLING CODE, ROUTINE OR VIRUS ; (B) THE
          OPERATION AND AVAILABILITY OF THE SYSTEMS USED FOR ACCESSING AND
          INTERACTING WITH JASPERACTIVE AND TO TRANSMIT INFORMATION CAN BE
          UNPREDICTABLE AND MAY FROM TIME TO TIME INTERFERE WITH OR PREVENT
          ACCESS TO OR USE OR OPERATION OF JASPERACTIVE.
        </li>
        <li className={classes.privacyDesc}>
          Limitation of Liability. JASPER LEARNING INC. SHALL NOT BE LIABLE FOR
          ANY INCIDENTAL OR CONSEQUENTIAL DAMAGES OR DAMAGES ARISING FROM
          FAILURE OF JASPERACTIVE TO FUNCTION IN ANY WAY, LOST OR CORRUPTED
          SYSTEMS OR SOFTWARE, LOSS OF PROFITS OR REVENUES OR BUSINESS
          OPPORTUNITIES OR LOST USE OF JASPERACTIVE, EVEN IF ADVISED OF THE
          POSSIBILITY OF THE SAME. NOTWITHSTANDING ANYTHING TO THE CONTRARY
          CONTAINED HEREIN, ANY LIABILITY OF JASPER LEARNING INC. IS LIMITED TO
          THE TOTAL AMOUNT RECEIVED BY JASPER LEARNING INC. FOR THE LICENSE
          GRANTED UNDER THIS EULA DURING THE TWELVE MONTHS IMMEDIATELY PRECEDING
          THE DATE ON WHICH THE PARTICULAR CLAIM AROSE.
        </li>
        <li className={classes.privacyDesc}>
          Equitable Remedies. You agree that money damages may be an inadequate
          remedy for Jasper Learning Inc. in the event of any actual or
          threatened breach by you or the licensee organization you represent of
          the terms of this EULA, and you agree that, in the event of such
          breach or threatened breach, in addition to any other remedies Jasper
          Learning Inc. is entitled to injunctive relief or specific performance
          as appropriate to protect its rights.
        </li>
        <li className={classes.privacyDesc}>
          Indemnification. At your own cost, you agree to defend Jasper Learning
          Inc. against any claim, action or proceeding brought against Jasper
          Learning Inc. which arises from or is in any manner connected with
          your unauthorized use of Jasperactive or any breach by you or the
          organization you represent of any term of this EULA.
        </li>
        <li className={classes.privacyDesc}>
          No Waiver. No waiver or breach or failure by Jasper Learning Inc. at
          any time or times to exercise any right, privilege or option available
          under this EULA will be construed as a waiver of any subsequent right,
          privilege or option.
        </li>
      </ol>
    </Box>
  );
};

export default EndUserLicenseAgreement;
