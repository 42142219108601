import {makeStyles} from '@material-ui/core/styles'
import {theme} from '../../theme'

const styles = makeStyles({
    container:{
        display:"flex",
        flex:"1",
        flexDirection:"column"
    },
    userInfo:{
        display:'flex',
        flex:"0.1",
        flexDirection:"column",
        minHeight:"20%",
        justifyContent:"space-around",
        backgroundColor:theme.palette.grey[100],
        marginTop:theme.spacing(6)
    },
    userName:{
        ...theme.typography.h1,
    },
    userType:{
        ...theme.typography.h3,
        color:theme.palette.grey[500],
    },
    getStartedButton:{
        color:theme.palette.common.white,
        backgroundColor:theme.palette.primary.main,
        height:"max-content",
        alignSelf:"center",
        marginRight:theme.spacing(3),
        '&:hover':{
            backgroundColor:theme.palette.primary.main,
        }
    },
    subscribeProduct:{
        ...theme.typography.h1,
        color:theme.palette.grey[500],
        marginTop: theme.spacing(6),
        marginBottom:theme.spacing(6)
    },
    productSection:{
        display:"flex",
        flexDirection:"column",
        flex:"0.8"
    }
})

export default styles;