import { Typography, useMediaQuery,useTheme } from '@material-ui/core';
import React from 'react'
import styles from './styles'
import IndividualPathway from './IndividualPathway'
import BenchmarkLogo from '../../../assets/logos/learningpathway/benchmark-icon.svg'
import LearnLogo from '../../../assets/logos/learningpathway/learn-icon.svg'
import PracticeLogo from '../../../assets/logos/learningpathway/practice-icon.svg'
import CreateLogo from '../../../assets/logos/learningpathway/create-icon.svg'
import FinalAssessmentLogo from '../../../assets/logos/learningpathway/final-assessment-icon.svg'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const LearningPathway = () => {

    const classes = new styles();
    const theme = useTheme();
    const isMatched = useMediaQuery(theme.breakpoints.down('xs'));

    const renderpathwayData = () => {
        let data = [
            {
                logo:BenchmarkLogo,
                title:"BENCHMARK",
                description:"The Benchmark assesses skill levels and builds a prescribed course plan using only the specific lessons required."
            },
            {
                logo:LearnLogo,
                title:"LEARN",
                description:"Learners begin with a pre-Lesson Quiz, then begin step-by-step instructional hands-on learning excercises along with access to an eBook."
            },
            {
                logo:PracticeLogo,
                title:"PRACTICE",
                description:"A chance to repeat Learn exercises for additional attempts and to perfect skills. Practice is also an excellent remediation option.",
            },
            {
                logo:CreateLogo,
                title:"CREATE",
                description:"A project-based exercise to combine skills and creativity. A standalone document is uploaded and scanned by Jasperactive to validate required project specifications."
            },
            {
                logo:FinalAssessmentLogo,
                title:"SUMMARY ASSESSMENT",
                description:"After learning, Jasperactive provides a practice exam to validate learning and to measure certification readiness."
            }
        ];
        return data;
    }

    return (
        <div  className={classes.container} >
            <Typography  className={classes.title} >
                Our Learning Pathway
            </Typography>
            <div className={classes.pathContainer} >
                    {renderpathwayData().map((data, i) => { 
                           return <React.Fragment key={i} >
                                       <IndividualPathway  logo={data.logo} title={data.title} description={data.description} /> 
                                       {renderpathwayData().length - 1 !== i ? 
                                            isMatched ? <ArrowDownwardIcon style={{alignSelf:"center", color:"#717073"}}/> : 
                                           <ArrowForwardIcon style={{alignSelf:"center", color:"#717073"}} />
                                       : ""}
                                   </React.Fragment> 

                           
                    })}                   
            </div>
        </div>
    )
}

export default LearningPathway;
