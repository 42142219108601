import { Button, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './styles'
import Banner from '../../assets/banner/homepage-banner.svg'
import Partners from '../Home/Partners'
import Products from '../Home/Products'
import LearningPathway from '../Home/LearningPathway'
import Testimonials from './Testimonials'
import DownArrow from '../../assets/other/down-arrow-icon.svg'
import { useAuthentication } from "../../providers/AuthenticationProvider";
import { scroller } from 'react-scroll'
import axios from 'axios'
import { Loading } from "../ui";

const Home = () => {
    const classes = new styles();
    const { user } = useAuthentication();
    const [loading, setLoading] = useState(true);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (user) {
            axios.get(`/Home/GetProducts/${window.location.hostname}`).then(response => {
                let data = response.data;
                let role = data.role;

                if ((role === "Instructor" && data.teacherProducts.length === 0) || (role === "Student" && data.studentProducts.length === 0)) {
                    setIsSubscribed(false);
                } else {
                    setIsSubscribed(true);
                }
            }).catch(error => {
                console.error(error);
            }).then(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [user])

    const goToproducts = () => {
        scroller.scrollTo('products', {
            smooth: true,
        })
    }

    const goToContactUs = () => {
        history.push({
            pathname: "/pricing",
            hash: "contactus"
        });
    }


    if (loading) {
        return <Loading />
    } else
        return (
            <div className={classes.container} >
                <div className={classes.brandSection}>
                    <div className={classes.brandLeft} >
                        <Typography className={classes.brandTitle} component="h1" >
                            What is Jasperactive?
                        </Typography>
                        <Typography component="p" className={classes.brandDesc} >
                            Jasperactive by CCI Learning is a cutting-edge learning system, hosted on Microsoft Azure that teaches learners various fundamental skills in technology, such as basics of using computers and the internet, business accounting, and for Microsoft is delivered through the Microsoft Office suite of applications.
                            <br /><br />The purpose of Jasperactive is to give learners a tool applicable to all learning styles that is kinesthetic and can be accessed anywhere and provides the freedom to learn at any pace.
                        </Typography>
                        <div className={classes.buttonContainer} >
                            <Button className={classes.exploreButton} variant="contained" onClick={goToproducts} >
                                EXPLORE PRODUCTS
                            </Button>
                            {!isSubscribed ?
                                <Button className={classes.requestDemoButton} variant="contained" onClick={goToContactUs}>
                                    Request Demo
                                </Button>
                                : ""}
                            <div className={classes.iconDiv} >
                                <img
                                    src={DownArrow}
                                    alt="downarrow"
                                    onClick={goToproducts}
                                />
                            </div>
                        </div>
                    </div>
                    <img
                        src={Banner}
                        alt="banner"
                        className={classes.bannerImage}

                    />
                </div>
                <Partners />
                <Products />
                <LearningPathway />
                <Testimonials />
            </div>
        )
}


export default Home;
