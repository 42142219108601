import React, { useContext, useEffect, useState } from "react";
import { Authentication } from "../lib/authentication";

export const AuthenticationContext = React.createContext();

export const AuthenticationProvider = ({ children }) => {
    const [authentication,] = useState(new Authentication());
    const [user, setUser] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [checkingAuth, setCheckingAuth] = useState(true);

    useEffect(() => {
        authentication.userManager.signinSilent().then(user => {
            if (user?.profile) {
                setUser(user?.profile);
            }
        }).catch(error => {
            if (error !== "ErrorResponse: login_required") {
                console.error("Error detecting user login:", error);
            }
        }).finally(() => setCheckingAuth(false));
    }, [authentication.userManager]);

    return (
        <AuthenticationContext.Provider value={{ authentication, checkingAuth, user, setUser, userRole, setUserRole }}>
            {children}
        </AuthenticationContext.Provider>
    );
}

export const useAuthentication = () => {
    const value = useContext(AuthenticationContext);
    return value;
};