import React from 'react'
import { useHistory, Link, withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import styles from './styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const BreadCrumbs = (props) => {

    const classes = new styles();
    const history = useHistory();
    const { location } = props;

    const goBack = () => {
        history.goBack();
    }

    return (
        <div className={classes.container}>
            <ArrowBackIcon className={classes.backarrow} onClick={goBack} />
            <Breadcrumbs  >
                <Link className={classes.links}  to="/">
                    Home
                </Link>
                <Typography className={classes.lastLink} >{location.pathname === "/dashboard" ? "User Dashboard" : ""}</Typography>
            </Breadcrumbs>
        </div>


    )
}


export default withRouter(BreadCrumbs);