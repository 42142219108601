import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { theme } from "../../theme";

const useStyles = makeStyles({
    step: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: theme.spacing(3),
    },
    stepNumber: {
        width: "30px",
        height: "30px",
        backgroundColor: theme.palette.product.adobeCreate,
        borderRadius: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white"
    },
});

const Step = ({ step, children }) => {
    const classes = useStyles();
    return (
        <Box className={classes.step}>
            <Box className={classes.stepNumber}>
                <Typography variant="h3" color="inherit">{step}</Typography>
            </Box>
            {children}
        </Box>
    );
};

export default Step;