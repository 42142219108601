import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
    container:{
        position:"fixed",
        bottom:0,
        left:0,
        right:0,
        backgroundColor:theme.palette.primary.main,
        zIndex:2,
        display:"flex",
        alignItems:"center",
        minHeight:theme.spacing(8),
        height:"fit-content"
    },
    title:{
        ...theme.typography.subtitle2,
        color:theme.palette.common.white,
        textAlign:"center"
    },
    btn:{
        ...theme.typography.subtitle2,
        width:"fit-content",
        backgroundColor:theme.palette.common.white,
        color:theme.palette.primary.main,
        marginLeft:theme.spacing(1),
        '&:hover':{
            backgroundColor:theme.palette.common.white
        }
    },
    privacyTitle:{
        ...theme.typography.h2,
        color:theme.palette.grey[500],
        fontWeight:500,
        marginTop:theme.spacing(2),
        marginBottom:theme.spacing(2)
    },
    privacyDesc:{
        ...theme.typography.body1,
        marginBottom:theme.spacing(2)
    },
    privacyHeader:{
        ...theme.typography.h4,
        marginTop:theme.spacing(3)
    },
    pdf:{
        boxShadow:"0px 0px 6px #000000",
        marginTop:theme.spacing(1)
    }
}));


export default styles;