import React, { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import styles from "./styles";

const DataProcessingAgreement = () => {
  const pathname = useLocation();
  const classes = styles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box>
      <Typography className={classes.privacyTitle}>
        Data Processing Agreement — Jasper Learning Inc.
      </Typography>
      <Typography className={classes.privacyDesc}>
        This Data Processing Agreement (<strong>“Agreement“</strong>) forms part
        of the Contract for Services (<strong>“Principal Agreement“</strong>)
        between
      </Typography>
      <Typography>
        ____________________________
        <br />
        ____________________________
        <br />
        ____________________________
      </Typography>
      <Typography>
        (the “<strong>Company</strong>”) and
      </Typography>
      <Typography>
        Jasper Learning Inc.
        <br /> 180 – 8621 201st Street
        <br /> Langley, B.C. V2Y 0G9
        <br /> (the “<strong>Data Processor</strong>”)
        <br /> (together as the “<strong>Parties</strong>”)
      </Typography>
      <Typography style={{ marginTop: 8, marginBottom: 8 }}>WHEREAS</Typography>
      <Typography
        className={classes.privacyDesc}
        style={{ marginTop: 8, marginBottom: 8 }}
      >
        (A) The Company acts as a Data Controller and as such it is the entity
        which determines the purposes and means of the Processing of Company
        Personal Data. Company shall, in its use of the Services, Process
        Company Personal Data in accordance with the requirements of Data
        Protection Laws and Regulations, including any applicable requirement to
        provide notice to Data Subjects of the use of Data Processor as
        Processor.
      </Typography>
      <Typography
        className={classes.privacyDesc}
        style={{ marginTop: 8, marginBottom: 8 }}
      >
        (B) The Company wishes to subcontract certain Services, which imply the
        processing of personal data, to the Data Processor.
      </Typography>
      <Typography
        className={classes.privacyDesc}
        style={{ marginTop: 8, marginBottom: 8 }}
      >
        (C) The Parties seek to implement a data processing agreement that
        complies with the requirements of the current legal framework in
        relation to data processing and with the Regulation (EU) 2016/679 of the
        European Parliament and of the Council of 27 April 2016 on the
        protection of natural persons with regard to the processing of personal
        data and on the free movement of such data, and repealing Directive
        95/46/EC (General Data Protection Regulation).
      </Typography>
      <Typography
        className={classes.privacyDesc}
        style={{ marginTop: 8, marginBottom: 8 }}
      >
        (D) The Parties wish to lay down their rights and obligations.
      </Typography>
      <Typography className={classes.privacyDesc}>
        IT IS AGREED AS FOLLOWS:
      </Typography>
      <Typography className={classes.privacyDesc}>
        1. Definitions and Interpretation <br />
        1.1 Unless otherwise defined herein, capitalized terms and expressions
        used in this Agreement shall have the following meaning:
        <br /> 1.1.1 “Agreement” means this Data Processing Agreement and all
        Schedules;
        <br /> 1.1.2 “Company Personal Data” means any Personal Data Processed
        by a Contracted Processor on behalf of Company pursuant to or in
        connection with the Principal Agreement;
        <br /> 1.1.3 “Contracted Processor” means a Subprocessor;
        <br /> 1.1.4 “Data Protection Laws” means EU Data Protection Laws and,
        to the extent applicable, the data protection or privacy laws of any
        other country;
        <br /> 1.1.5 “EEA” means the European Economic Area;
        <br /> 1.1.6 “EU Data Protection Laws” means EU Directive 95/46/EC, as
        transposed into domestic legislation of each Member State and as
        amended, replaced or superseded from time to time, including by the GDPR
        and laws implementing or supplementing the GDPR;
        <br />
        1.1.7 “GDPR” means EU General Data Protection Regulation 2016/679;
        <br /> 1.1.8 “Data Transfer” means:
        <br /> 1.1.8.1 a transfer of Company Personal Data from the Company to a
        Contracted Processor; or
        <br />
        1.1.8.2 an onward transfer of Company Personal Data from a Contracted
        Processor to a Subcontracted Processor, or between two establishments of
        a Contracted Processor, in each case, where such transfer would be
        prohibited by Data Protection Laws (or by the terms of data transfer
        agreements put in place to address the data transfer restrictions of
        Data Protection Laws);
        <br /> 1.1.9 “Services” means the Jasperactive services the Company
        provides.
        <br /> 1.1.10 “Subprocessor” means any person appointed by or on behalf
        of Processor to process Personal Data on behalf of the Company in
        connection with the Agreement.
        <br /> 1.2 The terms, “Commission”, “Controller”, “Data Subject”,
        “Member State”, “Personal Data”, “Personal Data Breach”, “Processing”
        and “Supervisory Authority” shall have the same meaning as in the GDPR,
        and their cognate terms shall be construed accordingly.
        <br />
        2. Processing of Company Personal Data
        <br /> 2.1 Processor shall:
        <br /> 2.1.1 comply with all applicable Data Protection Laws in the
        Processing of Company Personal Data; and
        <br /> 2.1.2 shall treat Company Personal Data as Confidential
        Information and shall Process Company Personal Data on behalf of and
        only in accordance with Company’s documented instructions for the of
        processing in accordance with the Agreement and applicable Order Form(s)
        and processing initiated by end-users in their use of the Services.
        <br />
        2.2 The Company instructs Processor to process Company Personal Data.
        <br /> 3. Processor Personnel Processor shall take reasonable steps to
        ensure the reliability of any employee, agent or contractor of any
        Contracted Processor who may have access to the Company Personal Data,
        ensuring in each case that access is strictly limited to those
        individuals who need to know / access the relevant Company Personal
        Data, as strictly necessary for the purposes of the Principal Agreement,
        and to comply with Applicable Laws in the context of that individual’s
        duties to the Contracted Processor, ensuring that all such individuals
        are subject to confidentiality undertakings or professional or statutory
        obligations of confidentiality.
        <br />
        4. Security
        <br /> 4.1 Taking into account the state of the art, the costs of
        implementation and the nature, scope, context and purposes of Processing
        as well as the risk of varying likelihood and severity for the rights
        and freedoms of natural persons, Processor shall in relation to the
        Company Personal Data implement appropriate technical and organizational
        measures to ensure a level of security appropriate to that risk,
        including, as appropriate, the measures referred to in Article 32(1) of
        the GDPR.
        <br /> 4.2 In assessing the appropriate level of security, Processor
        shall take account in particular of the risks that are presented by
        Processing, in particular from a Personal Data Breach.
        <br />
        5. Subprocessing
        <br />
        5.1 Processor shall not appoint (or disclose any Company Personal Data
        to) any Subprocessor unless required or authorized by the Company.
        <br /> 6. Data Subject Rights
        <br /> 6.1 Taking into account the nature of the Processing, Processor
        shall assist the Company by implementing appropriate technical and
        organisational measures, insofar as this is possible, for the fulfilment
        of the Company obligations, as reasonably understood by Company, to
        respond to requests to exercise Data Subject rights under the Data
        Protection Laws.
        <br /> 6.2 Processor shall:
        <br /> 6.2.1 promptly notify Company if it receives a request from a
        Data Subject under any Data Protection Law in respect of Company
        Personal Data; and
        <br />
        6.2.2 ensure that it does not respond to that request except on the
        documented instructions of Company or as required by Applicable Laws to
        which the Processor is subject, in which case Processor shall to the
        extent permitted by Applicable Laws inform Company of that legal
        requirement before the Contracted Processor responds to the request.
        <br /> 7. Personal Data Breach
        <br /> 7.1 Processor shall notify Company without undue delay upon
        Processor becoming aware of a Personal Data Breach affecting Company
        Personal Data, providing Company with sufficient information to allow
        the Company to meet any obligations to report or inform Data Subjects of
        the Personal Data Breach under the Data Protection Laws.
        <br /> 7.2 Processor shall co-operate with the Company and take
        reasonable commercial steps as are directed by Company to assist in the
        investigation, mitigation and remediation of each such Personal Data
        Breach.
        <br /> 8. Data Protection Impact Assessment and Prior Consultation
        Processor shall provide reasonable assistance to the Company with any
        data protection impact assessments, and prior consultations with
        Supervising Authorities or other competent data privacy authorities,
        which Company reasonably considers to be required by article 35 or 36 of
        the GDPR or equivalent provisions of any other Data Protection Law, in
        each case solely in relation to Processing of Company Personal Data by,
        and taking into account the nature of the Processing and information
        available to, the Contracted Processors.
        <br /> 9. Deletion or return of Company Personal Data
        <br /> 9.1 Subject to this section 9 Processor shall promptly and in any
        event within 10 business days of the date of cessation of any Services
        involving the Processing of Company Personal Data (the “Cessation
        Date”), delete and procure the deletion of all copies of those Company
        Personal Data.
        <br /> 10. Audit rights
        <br /> 10.1 Subject to this section 10, Processor shall make available
        to the Company on request all information necessary to demonstrate
        compliance with this Agreement, and shall allow for and contribute to
        audits, including inspections, by the Company or an auditor mandated by
        the Company in relation to the Processing of the Company Personal Data
        by the Contracted Processors.
        <br /> 10.2 Information and audit rights of the Company only arise under
        section 10.1 to the extent that the Agreement does not otherwise give
        them information and audit rights meeting the relevant requirements of
        Data Protection Law.
        <br /> 11. Data Transfer
        <br /> 11.1 The Processor may transfer or authorize the transfer of Data
        to countries outside the EU and/or the European Economic Area (EEA)
        without the prior written consent of the Company. If personal data
        processed under this Agreement is transferred from a country within the
        European Economic Area to a country outside the European Economic Area,
        the Parties shall ensure that the personal data are adequately
        protected. To achieve this, the Parties shall, unless agreed otherwise,
        rely on EU approved standard contractual clauses for the transfer of
        personal data which form the basis for this Agreement.
        <br /> 12. General Terms
        <br /> 12.1 Confidentiality. Each Party must keep this Agreement and
        information it receives about the other Party and its business in
        connection with this Agreement (“Confidential Information”) confidential
        and must not use or disclose that Confidential Information without the
        prior written consent of the other Party except to the extent that:
        <br /> (a) disclosure is required by law;
        <br /> (b) the relevant information is already in the public domain.
        <br /> 12.2 Notices. All notices and communications given under this
        Agreement must be in writing and will be delivered personally, sent by
        post or sent by email to the address or email address set out in the
        heading of this Agreement at such other address as notified from time to
        time by the Parties changing address.
        <br /> 13. Governing Law and Jurisdiction
        <br /> 13.1 This Agreement is governed by the laws of the Province of
        British Columbia.
        <br />
        14. Application
        <br /> 14.1 If the Company signing this Agreement is a party to a Master
        Sales Agreement or to an Order Form with the Data Processor, this
        Agreement is an addendum to and forms part of that Master Services
        Agreement or Order Form.
        <br /> 14.1 If the Company signing this Agreement is neither a party to
        an Order Form nor a Master Services Agreement, this Agreement is not
        valid and is not legally binding on the Data Processor.
        <br />
        15. Limitation of Liability
        <br /> 14.1 This Agreement as an addendum to and part of that Master
        Services Agreement or Order Form is subject to the ‘Limitation of
        Liability’ section of the Master Sales Agreement, and any reference in
        such section to the liability of a party means the aggregate liability
        of that party and all of its Affiliates under the Master Sales Agreement
        and all Data Processing Agreements together.
      </Typography>
      <Typography className={classes.privacyDesc}>
        IN WITNESS WHEREOF, this Agreement is entered into with effect from the
        date first set out below.
      </Typography>
      <Typography>Your Company</Typography>
      <Typography>Signature ____________________________</Typography>
      <Typography>Name: _______________________________</Typography>
      <Typography>Title: _________________________________</Typography>
      <Typography>Date Signed: _________________________</Typography>
      <Typography className={classes.privacyHeader}>
        Jasper Learning Inc.
      </Typography>
      <Typography>Signature _____________________________</Typography>
      <Typography>Name ________________________________</Typography>
      <Typography>Title CFO______________________________</Typography>
      <Typography>Date Signed ___________________________</Typography>
    </Box>
  );
};

export default DataProcessingAgreement;
