import React from "react";

const JDL = () => {
  return (
    <div style={{
      display: 'flex',
      flex: 1,
      width: '100%',
      flexDirection: 'column'
    }}>
      <iframe 
      title="Jasperactive Digital Literacy"
      src="https://jdl.jasperactive.com/"
      width={'100%'}
      frameBorder="0"
      style={{
        flex: 1
      }}
      ></iframe>
    </div>
  )
}

export default JDL;