import React from "react";
import { useAuthentication } from '../../../providers/AuthenticationProvider';
import { Loading } from "../../ui";

const SilentRenew = () => {
    const { authentication } = useAuthentication();
    React.useEffect(() => {
        const silentRenew = async () => {
            await authentication.userManager.signinSilentCallback();
        };

        silentRenew();
    }, [authentication.userManager]);

    return <Loading />;
};

export default SilentRenew;
