import React, { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import styles from "./styles";

const StudentPrivacyPledge = () => {
  const { pathname } = useLocation();
  const classes = styles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box display="flex" flexDirection="column">
      <Typography className={classes.privacyTitle}>
        Jasper Learning Inc., CCI Learning Solutions Inc. Student Privacy Pledge{" "}
      </Typography>
      <Typography>
        At Jasper Learning Inc. and CCI Learning Solutions Inc. (“Jasper & CCI”,
        “we”, “us” or “our”) your privacy is important to us. At Jasper & CCI,
        we collect and process personal private information in the capacity of
        Controller or Processer. Data collected via our web site and from
        Customers in that capacity we are Controllers and as such we control,
        maintenance and process such Personal Data in accordance with our
        Privacy Policy. As a K-12 School Service Provider, through our
        Jasperactive Learning Programs, we process personal private information
        in the capacity of Processer through our data processing agreement with
        our customer.
        <br />
        <br /> As a K-12 School Service Provider we are honored to be entrusted
        by educators and families to support their educational needs and school
        operations. As a School Service Provider, we take responsibility to both
        support the effective use of student information and safeguard student
        privacy and information security.
        <br />
        <br /> As a School Service Provider, we understand it is critical that
        schools and School Service Providers build trust by effectively
        protecting the privacy of student information and communicating with
        parents about how student information is used and safeguarded. Student
        Personally Identifiable Information is provided by students, their
        parents, and their schools; it should be used to serve and support
        students’ best interests.
        <br />
        <br /> In accordance with our Data Processing Agreement with our
        Customers (Schools and School Boards or Districts) and our
        responsibilities thereunder, we pledge to carry out responsible
        stewardship and appropriate use of Student PII according to the
        commitments below and in adherence to all laws applicable to us as
        School Service Providers and commit that:
      </Typography>
      <Box mt={2} display="flex">
        <ClearIcon color="primary" />
        <Typography style={{ marginLeft: 8 }}>
          We will not collect, maintain, use or share Student PII beyond that
          needed for authorized educational/school purposes, or as authorized by
          the parent/student.{" "}
        </Typography>
      </Box>
      <Box mt={2} display="flex">
        <ClearIcon color="primary" />
        <Typography style={{ marginLeft: 8 }}>
          We will not sell student PII.
        </Typography>
      </Box>
      <Box mt={2} display="flex">
        <ClearIcon color="primary" />
        <Typography style={{ marginLeft: 8 }}>
          We will not use or disclose student information collected through an
          educational/school service (whether personal information or otherwise)
          for behavioral targeting of advertisements to students.
        </Typography>
      </Box>
      <Box mt={2} display="flex">
        <ClearIcon color="primary" />
        <Typography style={{ marginLeft: 8 }}>
          We will not build a personal profile of a student other than for
          supporting authorized educational/school purposes or as authorized by
          the parent/student.
        </Typography>
      </Box>
      <Box mt={2} display="flex">
        <ClearIcon color="primary" />
        <Typography style={{ marginLeft: 8 }}>
          We will not make material changes to School Service Provider education
          privacy policies without first providing prominent notice to the users
          and/or account holder(s) (i.e., the institution/agency, or the
          parent/student when the information is collected directly from the
          student with student/parent consent) and allowing them choices before
          data is used in any manner inconsistent with terms they were initially
          provided; and not make material changes to other policies or practices
          governing the use of Student PII that are inconsistent with
          contractual requirements.
        </Typography>
      </Box>
      <Box mt={2} display="flex">
        <ClearIcon color="primary" />
        <Typography style={{ marginLeft: 8 }}>
          We will not knowingly retain Student PII beyond the time period
          required to support the authorized educational/school purposes, or as
          authorized by the parent/student.
        </Typography>
      </Box>
      <Box mt={2} display="flex">
        <CheckIcon style={{ color: "green" }} />
        <Typography style={{ marginLeft: 8 }}>
          We will collect, use, share, and retain Student PII only for purposes
          for which we were authorized by the educational institution/agency,
          teacher or the parent/student.
        </Typography>
      </Box>
      <Box mt={2} display="flex">
        <CheckIcon style={{ color: "green" }} />
        <Typography style={{ marginLeft: 8 }}>
          We will disclose clearly in contracts or privacy policies, including
          in a manner easy for institutions and parents to find and understand,
          what types of Student PII we collect, if any, and the purposes for
          which the information we maintain is used or shared with third
          parties.
        </Typography>
      </Box>
      <Box mt={2} display="flex">
        <CheckIcon style={{ color: "green" }} />
        <Typography style={{ marginLeft: 8 }}>
          We will support access to and correction of Student PII by the student
          or their authorized parent, either by assisting the educational
          institution in meeting its requirements or directly when the
          information is collected directly from the student with student/parent
          consent.
        </Typography>
      </Box>
      <Box mt={2} display="flex">
        <CheckIcon style={{ color: "green" }} />
        <Typography style={{ marginLeft: 8 }}>
          We will maintain a comprehensive security program that is reasonably
          designed to protect the security, confidentiality, and integrity of
          Student PII against risks – such as unauthorized access or use, or
          unintended or inappropriate disclosure – through the use of
          administrative, technological, and physical safeguards appropriate to
          the sensitivity of the information.
        </Typography>
      </Box>
      <Box mt={2} display="flex">
        <CheckIcon style={{ color: "green" }} />
        <Typography style={{ marginLeft: 8 }}>
          We will provide resources to support educational
          institutions/agencies, teachers, or parents/students to protect the
          security and privacy of Student PII while using the educational
          service.
        </Typography>
      </Box>
      <Box mt={2} display="flex">
        <CheckIcon style={{ color: "green" }} />
        <Typography style={{ marginLeft: 8 }}>
          We will require that our vendors with whom Student PII is shared in
          order to deliver the educational service, if any, are obligated to
          follow these same commitments for the given Student PII.
        </Typography>
      </Box>
      <Box mt={2} display="flex">
        <CheckIcon style={{ color: "green" }} />
        <Typography style={{ marginLeft: 8 }}>
          We will allow a successor entity to maintain the Student PII, in the
          case of our merger or acquisition by another entity, provided the
          successor entity is subject to these same commitments for the
          previously collected Student PII.
        </Typography>
      </Box>
      <Box mt={2} display="flex">
        <CheckIcon style={{ color: "green" }} />
        <Typography style={{ marginLeft: 8 }}>
          We will incorporate privacy and security when developing or improving
          our educational products, tools, and services and comply with
          applicable laws.
        </Typography>
      </Box>
      <Typography className={classes.privacyHeader}>Notes:</Typography>
      <ul>
        <li>
          This pledge is intended to be applicable to new contracts and policies
          going forward and addressed — where inconsistent and as agreed to by
          the educational institution or agency — in existing contracts as
          updated over time.
        </li>
        <li>This Pledge shall be effective as of December 1, 2020.</li>
      </ul>
      <Typography className={classes.privacyHeader}>Definitions: </Typography>
      <ul>
        <li>
          ‘School Service Provider’ refers to any entity that: (1) is providing,
          and is operating in its capacity as a provider of, an online or mobile
          application, online service or website that is marketed for use in
          United States elementary and secondary educational institutions/
          agencies and is used at the direction of their teachers or other
          employees; and (2) collects, maintains or uses Student PII in
          digital/electronic format. The term ‘School Service Provider’ does not
          include an entity that is providing, and that is operating in its
          capacity as a provider of, general audience software, applications,
          services or websites not designed and marketed for schools.{" "}
        </li>
        <li>
          ‘Educational/School purposes’ are services or functions that
          customarily take place at the direction of the educational
          institution/agency or their teacher/employee, for which the
          institutions or agency would otherwise use its own employees, and that
          aid in the administration or improvement of educational and school
          activities (e.g., instruction, administration, and development and
          improvement of products/services intended for educational/school use).{" "}
        </li>
        <li>
          ‘Signatory’ refers to a School Service Provider that has signed the
          Student Privacy Pledge.{" "}
        </li>
        <li>
          ‘Student PII’ is a student’s personally identifiable information. The
          term Student PII has the same definition as “covered information”
          under California’s Student Online Personal Information Protection Act
          (SOPIPA), Cal. Bus. & Prof. Code § 22584(i) (2020). Student PII does
          not include de-identified information.{" "}
        </li>
        <li>
          ‘Student’ applies to students of United States elementary and
          secondary schools, and with regard to notice and consent applies only
          to students of appropriate age as authorized under relevant United
          States federal law. See FERPA, 20 U.S.C. § 1232g; 34 CFR Part 99.{" "}
        </li>
        <li>
          ‘Consumer privacy policies’ include those privacy policies that are
          posted by the company to be available to all users to the site or
          service.
        </li>
        <li>‘Parent’ includes a student’s legal guardian. </li>
        <li>
          Controller” means the entity which determines the purposes and means
          of the Processing of Personal Data.
        </li>
        <li>
          “Customer” means the entity (or affiliated group) that has executed an
          Order for the Jasperactive products and who is considered the
          Controller of any Personal Data or other Data stored or maintained
          therein.{" "}
        </li>
        <li>
          “Personal Data” means any information relating to (i) an identified or
          identifiable natural person and, (ii) an identified or identifiable
          legal entity (where such information is protected similarly as
          personal data or personally identifiable information under applicable
          Data Protection Laws and Regulations), where for each (i) or (ii),
          such data is Customer Data.{" "}
        </li>
        <li>
          “Processing” means any operation or set of operations which is
          performed upon Personal Data, whether or not by automatic means, such
          as collection, recording, organization, structuring, storage,
          adaptation or alteration, retrieval, consultation, use, disclosure by
          transmission, dissemination or otherwise making available, alignment
          or combination, restriction, erasure or destruction.{" "}
        </li>
        <li>
          “Processor” means the entity which Processes Personal Data on behalf
          of the Controller, including as applicable any “service provider” as
          that term is defined by the CCPA.{" "}
        </li>
        <li>
          “K-12 School Service Providers” means a Processor that supports
          educators and students and their educational needs and schools’
          operations.{" "}
        </li>
        <li>
          “Student PI” means Personal Data that is provided by students, their
          parents, and their schools to the Jasperactive application{" "}
        </li>
      </ul>
      <Typography style={{ marginTop:16, fontStyle:"italic" }} >
        This Pledge is a promise by Jasper & CCI that it will adhere to its
        commitments. The Pledge is neither intended as a comprehensive privacy
        policy nor to be inclusive of all requirements to achieve compliance
        with all applicable federal or state laws. This Pledge is not a
        third-party audit, and it is not officially endorsed by any governmental
        agency that has regulatory or enforcement authority.
      </Typography>
    </Box>
  );
};

export default StudentPrivacyPledge;
