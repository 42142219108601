import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../theme";

const styles = makeStyles({
    appBar: {
        zIndex: 1400,
    },
    infoPanel: {
        backgroundColor: theme.palette.grey[400],
        height: "40px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down('md')]:{
            display:"none"
        }

    },
    infoText: {
        textDecorationLine: "underline",
        paddingRight: theme.spacing(1),
        color: theme.palette.common.white,
        '&:hover': {
            cursor: "pointer"
        }
    },
    separator: {
        height: "inherit",
        border: "1px solid white",
        marginRight: theme.spacing(1),
    },
    infoContainerLeft: {
        display: "flex",
        paddingLeft: theme.spacing(3),
    },
    infoContainerRight: {
        display: "flex",
        paddingRight: theme.spacing(3),
    },
    infoPanelDescription:{
        color:"white", 
        paddingRight:theme.spacing(1),
        [theme.breakpoints.down('xs')]:{
            display:"none"
        }
        
    },
    navContainer: {
        display: "flex",
        flexGrow: 1,
        justifyContent:"center"
    },
    navItems: {
        display: "flex",
        flexGrow: 0.5,
        justifyContent: 'space-evenly',
        alignItems:"center",
        listStyleType: "none",
        [theme.breakpoints.down('md')]:{
            display:"none"
        }
    },
    navIndividualItem: {
        ...theme.typography.body1,
        textDecorationLine:'none',
        [theme.breakpoints.down('md')]:{
            marginTop:theme.spacing(1),
            marginBottom:theme.spacing(1),
            marginLeft:theme.spacing(2),
        }
    },
    activeLink:{
        ...theme.typography.h4,
        color:theme.palette.primary.main
        
    },
    loginButton: {
        ...theme.typography.h4,
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.common.white,
        marginRight: theme.spacing(8),
        '&:hover': {
            backgroundColor: theme.palette.primary.main
        },
        [theme.breakpoints.down("md")]:{
            marginRight:"0px"
        },
        [theme.breakpoints.down('xs')]:{
            fontSize:"12px"
        }
        
        
    },
    dashboardSection:{
        display:"flex",
        alignItems:"center",
        '&:hover':{
            cursor:"pointer"
        }
        
    },
    userDashboardTitle:{
        ...theme.typography.body1,
        marginLeft:theme.spacing(1),
        [theme.breakpoints.down('xs')]:{
            display:"none"
        }
        
    },
    userDhaboarMenuItems:{
        ...theme.typography.subtitle2
    },
    drawer:{
        width:"193px",
        display:"flex",
        flexDirection:"column",
        alignItems:"flex-start",
    },
    appToolBar:theme.mixins.toolbar,
    burgerIcon:{
        color:theme.palette.primary.main,
        marginRight:theme.spacing(1)
    },
    brandLogo:{
        [theme.breakpoints.down('xs')]:{
            width:"124px"
        },
        '&:hover':{
            cursor:'pointer'
        }
    }
});


export default styles;