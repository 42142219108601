import React from 'react'
import styles from './styles'
import JasperActiveLogo from '../../../assets/logos/brand/jasperactive-main-logo.svg'
import { Typography , useMediaQuery } from '@material-ui/core';
import { theme } from '../../../theme'
import {ReactComponent as FacebookIcon} from '../../../assets/logos/socialmedia/Facebook-Logo.svg'
import {ReactComponent as InstagramIcon} from '../../../assets/logos/socialmedia/Instagram-Logo.svg'
import {ReactComponent as LinkedInIcon }  from '../../../assets/logos/socialmedia/Linkedin-Logo.svg'
import {ReactComponent as TwitterIcon}  from '../../../assets/logos/socialmedia/Twitter-Logo.svg'
import {ReactComponent as YoutubeIcon} from '../../../assets/logos/socialmedia/Youtube-Logo.svg'



const Footer = () => {

    const classes = new styles();
    const isMatched = useMediaQuery(theme.breakpoints.down('sm') );
    const isMobile = useMediaQuery( theme.breakpoints.down('xs') );
    return (
        <footer className={classes.container} >
            <div className={classes.individualContainer} style={{flexGrow:"0.8", marginRight:theme.spacing(2) }} >
                  <img 
                    src={JasperActiveLogo}
                    alt="jasperactivelogo"
                    style={{maxWidth:"350px", width: isMatched ? "250px" : "", alignSelf: isMobile ? "flex-start" : "" }}
                  />  
                  <div className={classes.headeOfficeSection} >
                        <div className={classes.headOfficeIndivdual} style={{ marginRight:theme.spacing(2) }} >
                            <Typography  className={classes.headOfficeTitle}>
                                Canada Head Office
                            </Typography>
                            <Typography className={classes.headOfficeAddress} >
                            Langley 200 Business Centre - Suite #180 <br/>
                            8621 201st Street Langley, BC
                            </Typography>
                        </div>
                        <div className={classes.headOfficeIndivdual} >
                            <Typography  className={classes.headOfficeTitle}>
                            USA Head Office
                            </Typography>
                            <Typography className={classes.headOfficeAddress} >
                            60 E Rio Salado Parkway, Suite 900​, <br/>
                            Tempe, AZ, 85281
                            </Typography>
                        </div>
                  </div>
            </div>
            <div className={classes.individualContainer} style={{flexGrow:"0.0", alignItems: isMatched ? "flex-start" : "", width:"max-content" }} >
                <Typography className={classes.connectTitle} >
                    Connect with us
                </Typography>
                <div className={classes.emailandphoneSection} >
                    <Typography className={classes.phoneandemailtitle}  style={{marginRight:theme.spacing(4)}}>
                        Phone
                    </Typography>
                    <Typography className={classes.phoneandemail} >
                        +1 800-668-1669
                    </Typography>
                </div>
                <div className={classes.emailandphoneSection} >
                    <Typography className={classes.phoneandemailtitle}  style={{marginRight:theme.spacing(5)}}>
                        Email
                    </Typography>
                    <Typography className={classes.phoneandemail} component="a" style={{textDecoration:"underline"}}  >
                        hello@jasperactive.com
                    </Typography>
                </div>
                <div className={classes.socialSection}   >
                    <FacebookIcon className={classes.socialIcon} onClick={() => { window.open("https://www.facebook.com/JasperactiveLive/photos/?ref=page_internal", "_blank") }} />
                    <InstagramIcon className={classes.socialIcon} onClick={() => { window.open("https://www.instagram.com/jasperactive/", "_blank") }} />
                    <TwitterIcon className={classes.socialIcon} onClick={() => { window.open("https://twitter.com/jasperactive?lang=en", "_blank") }} />
                    <YoutubeIcon className={classes.socialIcon} onClick={() => { window.open("https://www.youtube.com/channel/UCXQkOPOgSA4qKQtqCMO7khw", "_blank") }} />
                    <LinkedInIcon className={classes.socialIcon} onClick={() => { window.open("https://www.linkedin.com/company/cci-learning-solutions-inc./", "_blank") }} />
                </div>
            </div>
         </footer>
    )
}

export default Footer;
