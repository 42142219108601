import {makeStyles} from '@material-ui/core/styles'
import {theme} from '../../../theme'

const styles = makeStyles({
    container:{
        display:"flex",
        flexDirection:"column",
        marginTop:theme.spacing(16)
    },
    title:{
        ...theme.typography.h3,
        fontWeight:600,
        color:theme.palette.grey[400]
    },
    carousel:{
        marginTop:theme.spacing(2),
        height:"100%"
    },
    partners:{
        display:"flex",
        '&:hover':{
            cursor:"pointer"
        }
    }
})


export default styles;