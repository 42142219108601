import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./styles";
import { Box, Typography } from "@material-ui/core";

export default function TermsOfService() {
  const { pathname } = useLocation();
  const classes = styles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Box display="flex" flexDirection="column">
      <Typography className={classes.privacyTitle}>Terms Of Service</Typography>
      <Typography className={classes.privacyDesc}>
        Jasper Learning Inc., and its affiliates (“Jasper”, “us”, “we” or “our”)
        terms of services as used in these Jasper Terms of Use (“Terms”) refer
        to the products and services, websites, content, databases, software,
        technologies, and tools (“Services”) delivered by Jasper and that link
        or refer to these Terms or that are delivered through a website or
        server or service that links or refers to these Terms.
      </Typography>
      <Typography className={classes.privacyDesc}>
        Your access to and use of the Services is based on your acceptance of
        and compliance with these Terms, which apply to all visitors, users and
        others who access or use the Services.
      </Typography>
      <Typography className={classes.privacyDesc}>
        Please read these Terms carefully before using the Services.
      </Typography>
      <Typography className={classes.privacyDesc}>
        By accessing or using the Services you agree to be bound by these Terms
        and accept all legal consequences. If you do not agree to the Terms, in
        whole or in part, please do not use the Services.
      </Typography>
      <Typography className={classes.privacyDesc}>
        By accessing, browsing, registering for, and/or using any Service, or by
        accepting these Terms through an “I Accept” check-box or similar method,
        you acknowledge that you have read, understood, and agree to be bound by
        and comply with all terms in this statement. You may also agree to these
        Terms by physically or electronically signing an Order (as defined
        below) that incorporates these Terms. These Terms, along with any Order,
        form a legally binding agreement between you and JASPER.
      </Typography>
      <Typography className={classes.privacyDesc}>
        These Terms apply exclusively to your use of the Services and do not
        alter the terms or conditions of any other agreement you may have with
        us.
      </Typography>
      <Typography className={classes.privacyDesc}>
        The use of certain of our Services may be subject to added terms and
        conditions which may be presented before purchase or use.
      </Typography>
      <Typography className={classes.privacyDesc}>
        If you are accepting these Terms on behalf of another organization or
        entity, you represent that you are duly authorized to bind that
        organization or entity to these Terms.
      </Typography>
      <Typography className={classes.privacyHeader}>Your Privacy</Typography>
      <Typography className={classes.privacyDesc}>
        We are committed to protecting your privacy and developing technology
        that gives you the most powerful and safe online experience. Please read
        the Jasper Statement of Privacy (“Statement of Privacy”). The Statement
        of Privacy applies to the Jasper web site and the Services we provide
        and governs data collection and usage. To the extent provided by law, by
        agreeing to these Terms, you consent to the data practices described in
        the Statement of Privacy. In some cases, we will provide separate notice
        and request your consent as referenced in the Statement of Privacy.
      </Typography>
      <Typography className={classes.privacyHeader}>
        Your Account/ Jasperactive Accounts
      </Typography>
      <Typography className={classes.privacyDesc}>
        Jasperactive accounts are for personal non-commercial purposes only. You
        may not authorize others to use your account, and you may not assign or
        transfer your account.
      </Typography>
      <Typography className={classes.privacyDesc}>
        You must keep your username and password confidential. You are
        responsible for all activities and liabilities associated with your
        account. You agree to immediately notify Jasper of any unauthorized use
        of your account of which you are aware.
      </Typography>
      <Typography className={classes.privacyHeader}>
        Institutional Licenses
      </Typography>
      <Typography className={classes.privacyDesc}>
        This Section applies only if an applicable Order specifies that you are
        acquiring a Service subscription for an institution.
      </Typography>
      <Typography className={classes.privacyDesc}>
        Subject to your payment of the applicable fees and taxes, you may
        authorize your students, faculty, and administrative staff
        (collectively, “Authorized Users”) to access and use the applicable
        Service for non-commercial educational or instructional use, on
        condition that those Authorized Users accept these Terms before or
        during their first use of that Service. For Licenses that include a
        limit on the number of Authorized Users, Authorized Users includes all
        individuals authorized to use the Service, regardless of whether any
        such individual is actively using that Service at a particular time. You
        shall ensure that Authorized Users and any other person that uses the
        Service through the use of your account comply with these Terms and the
        Supplementary Terms. You shall take all reasonable precautions necessary
        to safeguard against unauthorized access and use of the Service through
        your account, including ensuring that an Authorized User ceases use of
        the Service after that Authorized User is no longer associated with your
        institution.
      </Typography>
      <Typography className={classes.privacyHeader}>
        Accounts of Minors
      </Typography>
      <Typography className={classes.privacyDesc}>
        By using the Services, you represent that you have either reached the
        age of “majority” where you live or have valid parent or legal guardian
        consent to be bound by these Terms. If you are the parent or legal
        guardian of a minor who creates an account, you and the minor accept and
        agree to be bound by these Terms and are responsible for all use of such
        account or Services, including purchases, whether the minor’s account is
        now open or created later.
      </Typography>
      <Typography className={classes.privacyHeader}>
        Your Use of Service (Code of Conduct)
      </Typography>
      <Typography className={classes.privacyDesc}>
        By agreeing to these Terms, you’re agreeing that, when using the
        Services, you will follow these rules:
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginBottom: 0 }}>
        Do not do anything illegal.
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginBottom: 0 }}>
        Do not engage in any activity that exploits, harms, or threatens to harm
        anyone.
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginBottom: 0 }}>
        Do not engage in activity that is fraudulent, false or misleading (e.g.
        impersonating someone else, manipulating the Services to increase test
        scores, or affect rankings, ratings, or comments).
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginBottom: 0 }}>
        Do not circumvent any restrictions on access to or availability of the
        Services.
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginBottom: 0 }}>
        Do not engage in activity that is harmful to you, the Services, or
        others (e.g. transmitting viruses, stalking, posting terrorist content,
        communicating hate speech, or advocating violence against others).
      </Typography>
      <Typography className={classes.privacyDesc} style={{ marginBottom: 0 }}>
        Do not engage in activity that violates the privacy of others.
      </Typography>
      <Typography className={classes.privacyDesc}>
        Do not help others break these rules.
      </Typography>
      <Typography className={classes.privacyDesc}>
        If you violate these Terms, we will stop providing Services to you or we
        may close your account.
      </Typography>
      <Typography className={classes.privacyHeader}>
        Third Party Software Reliance and Updates
      </Typography>
      <Typography className={classes.privacyDesc}>
        [Applicable to all FORM FACTORS AND COMPANIES]
      </Typography>
      <Typography className={classes.privacyDesc}>
        We are committed to ensuring our courseware mirrors the associated
        application to the fullest extent possible. Although we are vigilant
        about ensuring the highest level of accuracy in our courses, we do not
        guarantee that software updates will not render portions of the content
        inaccurate. We strive to address relevant software updates on a yearly
        basis (for the most current version of the software only).
      </Typography>
      <Typography className={classes.privacyDesc}>
        Software vendors that we work with to bring our users the best user
        experience in courseware are constantly working on improvements and
        refinements into their applications. New features, new functionality and
        revised user interfaces are developed and pushed out to users
        periodically. While this means you get the latest functionality, it also
        means your application may differ from the provided learning content.
        For instance, the appearance of the interface or the exact sequence of
        steps required to perform particular tasks may have changed since the
        time of writing. In such cases, use the listed steps as a guideline,
        make adjustments as required, and check our web site periodically for
        courseware updates.
      </Typography>
      <Typography className={classes.privacyDesc}>
        The information in Jasper’s courseware was accurate at the time of
        publishing and is distributed on an “as is” basis, without warranty.
        While every precaution has been taken in the preparation of these
        Services, neither the author nor Jasper shall have any liability to any
        person or entity with respect to any liability, loss, or damage caused
        or alleged to be caused directly or indirectly by the instructions
        contained in these Services or by the computer software and hardware
        products described therein.
      </Typography>
      <Typography className={classes.privacyDesc}>
        The reader is expressly warned that software vendors we work with and
        develop courseware for may change, update, or enhance the product and/or
        program described herein at any time.
      </Typography>
      <Typography className={classes.privacyDesc}>
        All examples and references in our courseware are fictitious, the dates,
        screen captures, tax tables, and other content may vary at time of use.
      </Typography>
      <Typography className={classes.privacyHeader}>Jasperactive</Typography>
      <Typography className={classes.privacyDesc}>
        Jasperactive versions and updates are optimized for use with the
        specified application software version and build. Please visit our
        Change Log web page located on our support site and be sure to stay up
        to date with Jasperactive Technical Requirements.
      </Typography>
      <Typography className={classes.privacyHeader}>
        Violation of Rules and Regulations
      </Typography>
      <Typography className={classes.privacyDesc}>
        We may seek all remedies available at law and in equity for violations
        of the rules and regulations set forth in these Terms and our Services,
        including the right to block access from a particular Internet address
        to the Services.
      </Typography>
      <Typography className={classes.privacyHeader}>Trademarks</Typography>
      <Typography className={classes.privacyDesc}>
        All trademarks, service marks and logos on the Services are the property
        of their owners. We own various trademarks, service marks, and logos
        used on the Services, including Jasper Learning and Jasperactive.
      </Typography>
      <Typography className={classes.privacyDesc}>
        Unauthorized use of any trademarks, service marks or logos used on the
        Services may be a violation of national and international trademark
        laws. You must obtain our written permission before using any of our
        trademarks, service marks or logos.
      </Typography>
      <Typography className={classes.privacyHeader}>Copyright</Typography>
      <Typography className={classes.privacyDesc}>
        This Service is protected by Canadian, U.S., and international copyright
        laws. You may not modify, reproduce or distribute the design or layout
        of the Services or individual sections of the design or layout of the
        Services or our logos without our prior written permission.
      </Typography>
      <Typography className={classes.privacyHeader}>
        Disclaim Warranties
      </Typography>
      <Typography className={classes.privacyDesc}>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL CONTENT ON THIS
        WEB SITE IS PROVIDED TO YOU “AS-IS AND “AS AVAILABLE,” WITH ALL FAULTS
        AND WITHOUT WARRANTY OF ANY KIND AND WE DISCLAIM ALL WARRANTIES WITH
        RESPECT TO THE SITE, EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING THE
        IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
        PURPOSE AND NON-INFRINGEMENT OF THIRD PARTY RIGHTS. WE MAKE NO WARRANTY
        AS TO THE ACCURACY, COMPLETENESS, CURRENCY OR RELIABILITY OF ANY CONTENT
        AVAILABLE THROUGH THE SITE. YOU ARE RESPONSIBLE FOR VERIFYING ANY
        INFORMATION BEFORE RELYING ON IT. USE OF THE SITE IS AT YOUR SOLE RISK.
      </Typography>
      <Typography className={classes.privacyDesc}>
        WE MAKE NO REPRESENTATIONS OR WARRANTIES THAT USE OF THE SITE WILL BE
        UNINTERRUPTED, COMPLETELY SECURE OR ERROR-FREE. You are responsible for
        taking all precautions necessary to ensure that any content you may
        obtain from the site is free of viruses.
      </Typography>
      <Typography className={classes.privacyHeader}>
        Limitation of Liability
      </Typography>
      <Typography className={classes.privacyDesc}>
        WE SPECIFICALLY DISCLAIM ANY LIABILITY, WHETHER BASED IN CONTRACT, TORT,
        STRICT LIABILITY OR OTHERWISE, FOR ANY DIRECT, INDIRECT, INCIDENTAL,
        CONSEQUENTIAL OR SPECIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED
        WITH ACCESS TO OR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES, INCLUDING RELIANCE BY ANY PARTY ON ANY
        CONTENT OBTAINED THROUGH USE OF THIS SITE, OR THAT ARISES IN CONNECTION
        WITH MISTAKES OR OMISSIONS IN, OR DELAYS IN TRANSMISSION OF, INFORMATION
        TO OR FROM THE USER, INTERRUPTIONS IN TELECOMMUNICATIONS CONNECTIONS TO
        THE SITE OR VIRUSES, WHETHER CAUSED IN WHOLE OR IN PART BY NEGLIGENCE,
        ACTS OF GOD, TELECOMMUNICATIONS FAILURE, THEFT OR DESTRUCTION OF, OR
        UNAUTHORIZED ACCESS TO THE WEB SITE, OR RELATED INFORMATION OR PROGRAMS.
      </Typography>
      <Typography className={classes.privacyDesc}>
        Some jurisdictions do not allow exclusions of liability for certain
        types of damages. Accordingly, some of the above limitations may not
        apply to you to the extent prohibited by law. In such cases, our
        liability is limited to the fullest extent permitted by applicable law.
      </Typography>
      <Typography className={classes.privacyHeader}>Indemnity</Typography>
      <Typography className={classes.privacyDesc}>
        You indemnify us and our subsidiaries, affiliates, officers, directors,
        agents, employees, partners, licensors and licensees from any claim or
        demand, including reasonable attorneys’ fees, made by any third party
        due to or arising out of your use of the Services, your violation of
        these Terms, or your violation of any rights of another. These include:
        (a) legal and accounting fees resulting from your use of the Services;
        (b) your breach of any of these Terms; and (c) anything you post on or
        upload to our site or Services.
      </Typography>
      <Typography className={classes.privacyHeader}>
        Linked Internet Sites
      </Typography>
      <Typography className={classes.privacyDesc}>
        Our Jasperactive Service does not contain any links to outside sites
        other than links to such additional external products that you, as our
        customer, has purchased and which has been accordingly linked to your
        Jasperactive Service.
      </Typography>
      <Typography className={classes.privacyHeader}>Modifications</Typography>
      <Typography className={classes.privacyDesc}>
        We may modify the Services and the rules and regulations governing their
        use, at any time. We will post modifications in the Services’ terms of
        service and revise the “last updated” date at the top of its page.
        Changes are effective once the revised information is posted on the
        Site. Your continued use of the Site after any such changes are posted
        constitutes acceptance of those changes.
      </Typography>
      <Typography className={classes.privacyDesc}>
        We may make changes in the products and services that are considered
        Services at any time.
      </Typography>
      <Typography className={classes.privacyHeader}>
        Service Availability
      </Typography>
      <Typography className={classes.privacyDesc}>
        The Services or material or products offered through the Services may be
        unavailable from time to time, may be offered for a limited time, or may
        vary depending on your region or device.
      </Typography>
      <Typography className={classes.privacyDesc}>
        We strive to keep the Services up and running; however, all online
        services suffer occasional disruptions and outages, and Jasper is not
        liable for any disruption or loss you may suffer as a result. In the
        event of an outage, you may not be able to retrieve your content or data
        that you’ve stored.
      </Typography>
      <Typography className={classes.privacyHeader}>
        Service Availability
      </Typography>
      <Typography className={classes.privacyDesc}>
        Customer support for some Services is available at{" "}
        <a href="http://support.jasperactive.com" target="_blank" rel="noreferrer" >
          http://support.jasperactive.com.
        </a>{" "}
        Certain Services may offer separate or additional customer support,
        subject to the terms available with their sale.
      </Typography>
      <Typography className={classes.privacyHeader}>Termination</Typography>
      <Typography className={classes.privacyDesc}>
        If your Services are canceled (whether by you or us), first your right
        to access the Services stops immediately and your license to the
        software related to the Services ends. Second, we will delete data or
        your content associated with your Service or will otherwise disassociate
        it from you and your Jasper account (unless we are required by law to
        keep it, return it, or transfer it to you or a third party identified by
        you). As a result, you may no longer be able to access any of the
        Services (or your content or information) that you’ve stored on those
        Services.
      </Typography>
      <Typography className={classes.privacyHeader}>
        Governing Law and General Principals
      </Typography>
      <Typography className={classes.privacyDesc}>
        These Terms and any claims relating to the information available on this
        site are governed by the laws of British Columbia and the laws of Canada
        enforceable in British Columbia.
      </Typography>
      <Typography className={classes.privacyDesc}>
        We operate our web site from our offices and third-party hosting
        services within Canada. We make no representation that the information
        in our web site is appropriate or available for use in other locations,
        and access to our web site from territories where the contents of our
        web site may be illegal is prohibited. Those who choose to access our
        web site from other locations do so on their own initiative and are
        responsible for compliance with applicable local laws.
      </Typography>
    </Box>
  );
}
