import React from 'react';
import OperatingSystemDialog from './OperatingSystemDialog';
import InstallationDialog from './InstallationDialog';

const Main = ({ open, setOpen }) => {
    const [openRequirements, setOpenRequirements] = React.useState(false);
    const [operatingSystem, setOperatingSystem] = React.useState("");

    const showRequirements = (_operatingSystem) => {
        setOpen(false)
        setOpenRequirements(true);
        setOperatingSystem(_operatingSystem);
    };

    const onBack = () => {
        setOpenRequirements(false);
        setOpen(true);
    };

    return (
        <React.Fragment>
            <OperatingSystemDialog open={open} onClose={() => setOpen(false)} onClick={showRequirements} />
            <InstallationDialog open={openRequirements} onClose={() => setOpenRequirements(false)} operatingSystem={operatingSystem} onBack={onBack} />
        </React.Fragment>
    );
};

export default Main;