import axios from 'axios';
import { getAccessToken } from "./authentication";

const getCurrentUrl = () => {
    return window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
};

const url = getCurrentUrl().toLocaleLowerCase();

const isDevEnvironment = url.indexOf("localhost") > -1 || url.indexOf("-home-dev") > -1 || url.indexOf("//dev.jasperactive.com") > -1;

const getIdentityUrl = () => {
    if (isDevEnvironment) {
        return "https://jasperactive-accounts-dev.azurewebsites.net";
    }
    else {
        return "https://accounts.jasperactive.com";
    }
};

const getApiUrl = () => {
    if (isDevEnvironment) {
        return "https://jasperactive-add-in-api-dev.azurewebsites.net/api";
    }
    else {
        return "https://add-in-api.jasperactive.com/api";
    }
};

export const getAvatarUrl = (avatar) => {
    switch (avatar) {
        case 'smile-pen':
            return 'https://res.cloudinary.com/jasperactive/image/upload/v1591897262/Avatar/smile-pen_gv2qos.svg';
        case 'smile-pencil':
            return 'https://res.cloudinary.com/jasperactive/image/upload/v1591897262/Avatar/smile-pencil_qbjfbo.svg';
        case 'smile-mug':
            return 'https://res.cloudinary.com/jasperactive/image/upload/v1591897262/Avatar/smile-mug_dsg6pt.svg';
        case 'smile-apple':
            return 'https://res.cloudinary.com/jasperactive/image/upload/v1591897261/Avatar/smile-apple_ji0gts.svg';

        default:
            return null;
    }
};

axios.defaults.baseURL = getApiUrl();

axios.interceptors.request.use(config => {
    const token = getAccessToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, function (error) {
    return Promise.reject(error);
});

const SETTNGS = {
    appUrl: url,
    identityUrl: getIdentityUrl(),
    apiUrl: getApiUrl(),
    isDevEnvironment
};

export default SETTNGS;
