import React from "react";
import { useHistory } from 'react-router-dom'
import { useAuthentication } from '../../../providers/AuthenticationProvider';
import { Loading } from "../../ui";

const AuthCallback = () => {
    const { authentication, setUser } = useAuthentication();
    const history = useHistory();

    React.useEffect(() => {
        const authCallback = async () => {
            try {
                const user = await authentication.userManager.signinRedirectCallback();
                if (user?.profile) {
                    setUser(user?.profile);
                }
            } catch (error) {
                console.error("AuthCallback ", error);
            } finally {
                history.push("/dashboard");
            }
        };

        authCallback();
    }, [authentication.userManager, history, setUser]);

    return <Loading />;
};

export default AuthCallback;
