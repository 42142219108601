import React from 'react'
import styles from './style'
import HeartIcon from '../../../assets/logos/testimonials/testimonials-heart-icon.svg'
import { Paper, Typography } from '@material-ui/core';

const  Testimonials = () => {

    const classes =  new styles();

    const renderTestimonials = () => {
        let testimonials = [
            {
                review:"“Our learners evidence a high MOS certification success rate subsequent of Jasperactive learning. Jasperactive enables our learners and alumni to keep current of MOS knowledge and skills whilst pursing post-secondary credentialing or pursuing exciting business careers with leading organizations.”",
                by:"- David Warner, Southern Alberta Institute of Technology (SAIT), Alberta, Canada"
            },
            {
                review:"“I wanted you to know how excited I am about Jasperactive, I love it and my students do too. I have a 91% passing rate in one class. We have never had this passing rate so early in the semester. Our Director of Schools has heard of our success and has sent a message of congratulations. Jasperactive has made my life so much easier, and students are learning so much as they train.”",
                by:"- Joe Bryan, Walker Valley High School, Tennessee, USA"
            },
            {
                review:"“Jasperactive has provided my students with an interactive interface to learn all the different functionalities of the Microsoft Office Suite Software. Jasperactive also assists me with grading the students' lessons and provides detailed reports on their progress for each software.”",
                by:"- Jerry Chen, Ècole Cariboo Hill Secondary School, British Columbia, Canada"
            },
            {
                review:"“Jasperactive truly spoils me as a teacher. My 8th grade students love receiving digital badges as it creates good fun for them! I need to lock my renewal in for multiple years because I can’t lose this program.”",
                by:"- Brenda Gilliland, Concordia High School, Kansas, USA"
            }
        ]
        return testimonials;
    }

    return (
        <div className={classes.container} >
            <div className={classes.headingSection} >
                    <img 
                        src={HeartIcon}
                        alt="heartIcon"
                        
                    />
                    <Typography className={classes.heading} >
                    Our customers all over the globe are loving Jasperactive.
                    </Typography>
            </div>
            <div className={classes.testimonialSection} >
                {renderTestimonials().map((value, i) => {
                    return <Paper key={i} className={classes.individualTestimonial} >
                            <Typography className={classes.review} >
                                    {value.review}
                            </Typography>
                            <Typography className={classes.reviewBy} >
                                    {value.by}
                            </Typography>
                    </Paper>
                })}
            </div>
        </div>
    )
}


export default Testimonials;