import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Button, Card, CardContent, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import { theme } from "../../theme";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import JACLogo from '../../assets/logos/products/jasperactive-ac.svg';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Requirements from './Requirements';
import Steps from './Steps';

const useStyles = makeStyles({
    dialog: {
        borderTop: `4px solid ${theme.palette.product.adobeCreate}`,
        backgroundColor: "#F2F2F2",
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    dialogContent: {
        backgroundColor: "#F2F2F2",
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    header: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'flex-start'
    },
    textBox: {
        display: "flex",
        [theme.breakpoints.up('xs')]: {
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
        },
    },
    text: {
        [theme.breakpoints.up('xs')]: {
            marginLeft: theme.spacing(2),
        },
    },
    buttonBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        height: "100px",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    button: {
        borderColor: theme.palette.product.adobeCreate
    },
    card: {
        borderTop: `4px solid ${theme.palette.product.adobeCreate}`,
        marginBottom: theme.spacing(3)
    },
    cardContent: {
        display: "flex",
        [theme.breakpoints.up('sm')]: {
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "flex-start",
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
        },
    },
    cardAction: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    }
});

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(({ children, classes, onClose, ...other }) => {
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const InstallationDialog = ({ open, onClose, onBack, operatingSystem }) => {
    const [showRequirements, setShowRequirements] = React.useState(true);
    const classes = useStyles();

    const handleClose = () => {
        onClose();
    };

    const getTitle = () => {
        switch (operatingSystem) {
            case "windows":
                return "Intallations guide for Windows"
            case "mac":
                return "Intallations guide for Mac"
            default:
                return "Intallations guide";
        }
    };

    const onClick = () => {
        setShowRequirements(false);
    };

    const onClickBack = () => {
        if (showRequirements) {
            onBack?.();
        }
        else {
            setShowRequirements(true);
        }
    };

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="md" >
            <DialogTitle className={classes.dialog} onClose={handleClose}>
                <Box className={classes.header}>
                    <img src={JACLogo} width={192} alt="Jasperactive Adobe Create" />
                    <Box mt={2}>
                        <Button startIcon={<ChevronLeftIcon />} onClick={onClickBack}>RETURN TO PREVIOUS WINDOW</Button>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Card className={classes.card}>
                    <CardContent>
                        <Box mt={2} mb={2}>
                            <Typography variant="h2">
                                {getTitle()}
                            </Typography>
                        </Box>
                        <Box className={classes.cardContent}>
                            {showRequirements ? <Requirements /> : <Steps operatingSystem={operatingSystem} />}
                        </Box>
                    </CardContent>
                </Card>
                {showRequirements &&
                    <Box className={classes.cardAction}>
                        <Button variant="outlined" size="small" style={{ backgroundColor: theme.palette.product.adobeCreate, color: "white" }} onClick={onClick}>Go to Instructions</Button>
                    </Box>
                }
            </DialogContent>
        </Dialog >
    );
};

export default InstallationDialog;