import React, { Fragment, useState } from "react";
import { NavLink as Link, useHistory } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  useMediaQuery,
  useTheme,
  Drawer,
  Collapse,
} from "@material-ui/core";
import styles from "./styles";
import InfoPanel from "./InfoPanel";
import BrandLogo from "../../../assets/logos/brand/jasperactive-main-logo.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import MenuIcon from "@material-ui/icons/Menu";
import { scroller } from "react-scroll";
import { useAuthentication } from "../../../providers/AuthenticationProvider";
import SETTINGS from "../../../lib/settings";
import { getAvatarUrl } from "../../../lib/settings";
import CloseIcon from "@material-ui/icons/Close";

export default function Header() {
  const { authentication, user, checkingAuth } = useAuthentication();
  const classes = new styles();

  const [drawer, setDrawer] = useState(false);
  const [openLegalMenu, setOpenLegalMenu] = useState(false);

  const [anchorEl, setAnchorEL] = useState(null);
  const [privacyAnchor, setPrivacyAnchor] = useState(null);
  const history = useHistory();

  const handleUserMenus = (e) => {
    !anchorEl ? setAnchorEL(e.currentTarget) : setAnchorEL(null);
  };

  const handleClose = () => {
    setAnchorEL(null);
  };

  const gotToDashBoard = (e) => {
    handleClose();
    history.push("/dashboard");
  };

  const handleDrawer = () => {
    drawer ? setDrawer(false) : setDrawer(true);
  };
  const goToproducts = (e) => {
    const id = e.target.id;
    if (id === "drawerProduct") {
      handleDrawer();
    }
    scroller.scrollTo("products", {
      smooth: true,
    });
  };
  const handleLogin = async () => {
    await authentication.userManager.signinRedirect();
  };
  const handleLogout = async () => {
    await authentication.userManager.signoutRedirect();
  };
  const handleAccountSettings = async () => {
    window.open(`${SETTINGS.identityUrl}/Account/UserProfile`, "_blank");
  };

  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down("md"));

  const goToSupport = (e) => {
    const id = e.target.id;
    if (id === "drawerSupport") {
      handleDrawer();
    }

    window.open("https://support.jasperactive.com/support/home", "_blank");
  };

  const goToBlog = (e) => {
    const id = e.target.id;
    if (id === "drawerBlog") {
      handleDrawer();
    }
    window.open("https://ccilearning.com/blog/", "_blank");
  };

  const handleLegalMenus = (e) => {
    !privacyAnchor ? setPrivacyAnchor(e.currentTarget) : setPrivacyAnchor(null);
    if (e) {
      e.preventDefault();
    }
  };

  const handleLegalMenusDrawer = () => {
    setOpenLegalMenu(!openLegalMenu);
  };

  const goToPrivacy = (e) => {
    const id = e.target.id;
    if (id === "drawerPrivacy") {
      handleDrawer();
    } else {
      handleLegalMenus();
    }
    history.push("/privacy-policy");
  };

  const goToEULA = (e) => {
    const id = e.target.id;
    if (id === "drawerEULA") {
      handleDrawer();
    } else {
      handleLegalMenus();
    }
    history.push("/end-user-license-agreement");
  }

  return (
    <Fragment>
      <AppBar className={classes.appBar} color="default" position="relative">
        <InfoPanel />
        <Toolbar component="div">
          {isMatched && !drawer ? (
            <MenuIcon onClick={handleDrawer} className={classes.burgerIcon} />
          ) : (
            ""
          )}
          {isMatched && drawer ? (
            <CloseIcon onClick={handleDrawer} className={classes.burgerIcon} />
          ) : (
            ""
          )}
          <img
            src={BrandLogo}
            alt="jasperActivelogo"
            className={classes.brandLogo}
            onClick={() => {
              history.push("/");
            }}
          />
          <nav className={classes.navContainer}>
            <ul className={classes.navItems}>
              <li>
                <Link
                  className={classes.navIndividualItem}
                  exact={true}
                  to="/"
                  activeClassName={classes.activeLink}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className={classes.navIndividualItem}
                  to="/"
                  activeClassName={classes.activeClassName}
                  onClick={goToproducts}
                >
                  Our Products
                </Link>
              </li>
              <li>
                <Link
                  className={classes.navIndividualItem}
                  to="/pricing"
                  activeClassName={classes.activeLink}
                >
                  Pricing
                </Link>
              </li>
              <li>
                <Link
                  className={classes.navIndividualItem}
                  to="/"
                  onClick={goToSupport}
                >
                  Support
                </Link>
              </li>
              <li>
                <Link
                  className={classes.navIndividualItem}
                  to="/"
                  onClick={goToBlog}
                >
                  Blog
                </Link>
              </li>
              <a
                href={window.location.href.toString()}
                className={classes.navIndividualItem}
                onClick={handleLegalMenus}>
                <div>
                  <li>Legal</li>
                  <Menu
                    style={{ zIndex: "1401" }} // Greater than App Bar
                    anchorEl={privacyAnchor}
                    open={Boolean(privacyAnchor)}
                    keepMounted
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem onClick={goToPrivacy}>
                      <Typography className={classes.userDhaboarMenuItems}>
                        Privacy
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={goToEULA} >
                      <Typography className={classes.userDhaboarMenuItems}>
                        End User License Agreement
                      </Typography>
                    </MenuItem>
                  </Menu>
                </div>
              </a>
              {user && (
                <li>
                  <Link
                    className={classes.navIndividualItem}
                    to="/dashboard"
                    activeClassName={classes.activeLink}
                  >
                    Dashboard
                  </Link>
                </li>
              )}
            </ul>
          </nav>
          {user ? (
            <div
              className={classes.dashboardSection}
              aria-haspopup="true"
              aria-controls="user-items"
              onClick={handleUserMenus}
            >
              <Avatar src={getAvatarUrl(user.avatar)} />
              <Typography className={classes.userDashboardTitle}>
                {user.name}
              </Typography>
              {!anchorEl ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </div>
          ) : (
            <Button
              variant="contained"
              disableElevation
              className={classes.loginButton}
              onClick={handleLogin}
              disabled={checkingAuth}
            >
              Login / Register
            </Button>
          )}
          <Menu
            style={{ zIndex: "1401" }} // Greater than App Bar
            id="user-items"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            keepMounted
            getContentAnchorEl={null}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={gotToDashBoard}>
              <Typography className={classes.userDhaboarMenuItems}>
                User Dashboard
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleAccountSettings}>
              <Typography className={classes.userDhaboarMenuItems}>
                Account Settings
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <Typography className={classes.userDhaboarMenuItems}>
                Logout
              </Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
        <Drawer open={drawer} anchor="left">
          <div className={classes.appToolBar} />
          <div className={classes.drawer}>
            <Link
              className={classes.navIndividualItem}
              exact={true}
              to="/"
              onClick={handleDrawer}
              activeClassName={classes.activeLink}
            >
              Home
            </Link>
            <Link
              className={classes.navIndividualItem}
              id="drawerProduct"
              to="/"
              onClick={(e) => {
                goToproducts(e);
              }}
            >
              Our Products
            </Link>
            <Link
              className={classes.navIndividualItem}
              onClick={handleDrawer}
              to="/pricing"
              activeClassName={classes.activeLink}
            >
              Pricing
            </Link>
            <Link
              className={classes.navIndividualItem}
              id="drawerSupport"
              onClick={(e) => {
                goToSupport(e);
              }}
              to="/"
            >
              Support
            </Link>
            <Link
              className={classes.navIndividualItem}
              id="drawerBlog"
              onClick={(e) => goToBlog(e)}
              to="/"
            >
              Blog
            </Link>
            <div
              style={{ display: "flex", flexDirection: "column", width: "90%" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography className={classes.navIndividualItem}>
                  Legal
                </Typography>
                <IconButton onClick={handleLegalMenusDrawer}>
                  {!openLegalMenu ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </div>
              <Collapse
                in={openLegalMenu}
                unmountOnExit
                style={{ marginLeft: 16 }}
              >
                <Typography
                  id="drawerPrivacy"
                  className={classes.navIndividualItem}
                  onClick={goToPrivacy}
                >
                  Privacy
                </Typography>
                <Typography
                  id="drawerEULA"
                  className={classes.navIndividualItem}
                  onClick={goToEULA}
                >
                  End User License Agreement
                </Typography>
              </Collapse>
            </div>
            {user && (
              <Link
                className={classes.navIndividualItem}
                to="/dashboard"
                activeClassName={classes.activeLink}
                onClick={handleDrawer}
              >
                Dashboard
              </Link>
            )}
          </div>
        </Drawer>
      </AppBar>
    </Fragment>
  );
}
