import { Card, CardContent, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react'
import styles from './style'



const Product = ({ logo, description, theme, type }) => {

    const classes = new styles();

    const history = useHistory();

    const goToProductDetail = () => {
        history.push("/" + type);
    }

    return (
        <Card className={classes.cardConatiner} style={{ borderTop: `4px solid ${theme}` }} onClick={goToProductDetail} >
            <CardContent className={classes.topContent}>
                <img
                    src={logo}
                    alt="abc"
                    style={{ width: "60%" }}
                />
            </CardContent>
            <CardContent>
                <Typography className={classes.description} >
                    {description}
                </Typography>
            </CardContent>
            <CardContent className={classes.learMore} >
                <Typography style={{ color: theme }} >
                    LEARN MORE
                </Typography>
                <ArrowForwardIcon style={{ color: theme, marginLeft: "10px" }} />
            </CardContent>
        </Card>
    )
}


export default Product;
