import {makeStyles} from '@material-ui/core/styles'
import {theme} from '../../../theme'


const styles = makeStyles({
    container:{
        display:"flex",
        flex:1,
        backgroundColor:theme.palette.grey[100],
        paddingTop:"24px",
        paddingBottom:"24px",
        paddingLeft:"10%",
        paddingRight:"10%",
        justifyContent:"space-around",
        marginTop:"180px",
        position:"relative",
        bottom:0,
        left:0,
        right:0,
        [theme.breakpoints.down('sm')]:{
            flexDirection:"column"
        }
    },
    individualContainer:{
        display:"flex",
        flexDirection:"column",
        paddingTop:theme.spacing(1.5),
        paddingBottom:theme.spacing(1.5),
        [theme.breakpoints.down('sm')]:{
            alignItems:"center",
            width:"100%"
        }
    },
    headeOfficeSection:{
        display:"flex",
        marginTop:theme.spacing(4),
        justifyContent:"space-between",
        width:"70%",
        [theme.breakpoints.down('md')]:{
            width:"100%"
        },
        [theme.breakpoints.down('xs')]:{
            flexDirection:"column"
        }
    },
    headOfficeIndivdual:{
        display:"flex",
        flexDirection:"column",
        [theme.breakpoints.down('xs')]:{
            marginBottom:theme.spacing(2)
        }
    },
    headOfficeTitle:{
        ...theme.typography.h4
    },
    headOfficeAddress:{
        fontSize:"16px",
        lineHeight:"32px",
        color:theme.palette.grey[500],
        marginTop:theme.spacing(2)
    },
    connectTitle:{
        ...theme.typography.h2,
        color: theme.palette.grey[500],
        marginTop:theme.spacing(2),
        marginBottom:theme.spacing(2),
        fontWeight:"500"
    },
    emailandphoneSection:{
        display:"flex",
        marginBottom:theme.spacing(2)
    },
    phoneandemailtitle:{
        ...theme.typography.h4,
        fontWeight:500
    },
    phoneandemail:{
        ...theme.typography.body1,
        fontWeight:"normal"
    },
    socialSection:{
        display:"flex",
        justifyContent:"space-between",
        width:"100%"
    },
    socialIcon:{
        '&:hover':{
            cursor:"pointer"
        }
    }
    
})


export default styles;